import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

// Import components
import NotFoundPage from "../pages/LandingPage/NotFoundPage";
import InspectionRequest from '../pages/InspectionRequest';
import InspectionRequestCompleted from '../pages/InspectionRequest/completedReports';
import CarpetBasic from "../pages/InspectionReport/CarpetBasic/CarpetBasic";
import QuickReport from "../pages/InspectionReport/QuickReport/QuickReport";
import QuickReportUpdate from "../pages/InspectionReport/QuickReport/QuickReportUpdate";
import SingleQuickReport from "../pages/SingleReports/SingleQuickReport";
import CarpetDetailed from "../pages/InspectionReport/CarpetDetail/CarpetDetailed";
import CustomerResolutionReportContainer from "../pages/InspectionReport/MohawkFieldService/CustomerResolutionReportContainer";
import SingleInspectionRequest from "../pages/SingleRequest/index";
import AllClients from '../pages/Clients';
import AllInvoices from '../pages/Invoices';
import IndustryStandards from '../pages/IndustryStandards';
import PublicStandards from '../pages/IndustryStandards/PublicStandards';
import CreateInvoice from "../pages/Invoices/CreateInvoice";
import HardSurfaceBasic from "../pages/InspectionReport/HardSurfaceBasic/HardSurfaceBasic";
import CreateCoverLetter from "../pages/CoverLetter/CreateCoverLetter";
import UpdateCoverLetter from "../pages/CoverLetter/UpdateCoverLetter";
import CoverLetter from "../pages/CoverLetter/CoverLetter";
import SingleInvoice from "../pages/Invoices/SingleInvoice";
import UpdateInvoice from "../pages/Invoices/UpdateInvoice";
import CreateEmailReport from "../pages/Email/createEmailReport";
import HardwoodDetailed from "../pages/InspectionReport/HardwoodDetailed/HardwoodDetailed";
import Attachments from '../pages/Attachments';
import Tile from "../pages/InspectionReport/Tile/Tile";
import Laminate from '../pages/InspectionReport/Laminate/Laminate';
import Lvt from "../pages/InspectionReport/LVT/Lvt";
import Addnewclient from "../pages/Clients/addNewClient";
import Manufacturer from "../pages/manufacturer/index";
import AllClaimAnalysts from "../pages/manufacturer/AllClaimAnalysts";
import Dealers from "../pages/manufacturer/dealers";
import AddDealer from "../pages/manufacturer/addDealer";
import AddClaimAnalyst from "../pages/manufacturer/addClaimAnalyst";
import SingleCarpetBasic from "../pages/SingleReports/SingleCarpetBasic";
import SingleCarpetDetailed from "../pages/SingleReports/SingleCarpetDeatiled";
import SingleHardwoodDetailed from "../pages/SingleReports/SingleHardwoodDetailed";
import SingleHardwoodBasic from "../pages/SingleReports/SingleHardwoodBasic";
import SingleTile from "../pages/SingleReports/SingleTile.js";
import SingleLvt from "../pages/SingleReports/SingleLvt";
import SingleLaminate from "../pages/SingleReports/SingleLaminate";
import Archived from "../pages/manufacturer/archived";
import CarpetBasicUpdate from "../pages/InspectionReport/CarpetBasic/updateCarpetBasic";
import CarpetDetailedUpdate from "../pages/InspectionReport/CarpetDetail/updateCarpetDetailed";
import HardwoodBasicUpdate from "../pages/InspectionReport/HardSurfaceBasic/updateHardSurfaceBasic";
import LvtUpdate from "../pages/InspectionReport/LVT/updateLvt";
import LaminateUpdate from "../pages/InspectionReport/Laminate/updateLaminate";
import HardwoodDetailedUpdate from "../pages/InspectionReport/HardwoodDetailed/updateHardwoodDetailed";
import CreateNewClient from "../pages/Clients/addNewClient";
import TileUpdate from "../pages/InspectionReport/Tile/updateTile";
import SingleClaimAnalyst from "../pages/SingleReports/SingleClaimAnalyst";
import SingleDealer from "../pages/DealerPanel/SingleDealer";
import AllClaimAnalystsUpdate from "../pages/manufacturer/updateClaimAnalyst";
import DealerUpdate from "../pages/manufacturer/updateDealer";
import ClaimAnalystPanel from "../pages/ClaimAnalystPanel";
import DealerPanel from "../pages/DealerPanel/";
import FsmanagerPanel from "../pages/FsmanagerPanel/";
import InspectorsCRUD from "../components/Inspectors/InspectorsCRUD.js";
import FsinspectorPanel from "../pages/FsinspectorPanel/";
import SendDicision from '../pages/SendDecision';
import NwfaInstallationInspectionReport from "../pages/InspectionReport/NwfaInstallationInspectionReport/NwfaInstallationInspection";
import NwfaSandFinishInspectionReport from "../pages/InspectionReport/NwfaSandFinish/NwfaSandFinish";
import SingleNwfaInstallation from "../pages/SingleReports/SingleNwfaInstallationReport";
import SingleNwfaSandReport from "../pages/SingleReports/SingleNwfaSandReport";
import NwfaSandFinishUpdate from "../pages/InspectionReport/NwfaSandFinish/updateNwfaSandFinish";
import NwfaInstallationUpdate from "../pages/InspectionReport/NwfaInstallationInspectionReport/updateNwfaInstallation";
import SingleViewClient from "../pages/SingleReports/SingleViewClient";
import UpdateClientView from "../pages/Clients/updateViewClient";
import InspectorProfile from "../pages/InspectorProfile.js";
import UserProfilePage from "../pages/UserProfilePage.js";
import UnAssignedClaims from "../pages/Mohawk/UnAssignedClaims.js";
import FieldServicesClaims from "../pages/Mohawk/FieldServicesClaims.js";

const AppRoute = () => {
  const userDetails = useSelector((state) => state.User.userDetails);
  const userRole = userDetails?.user_role;

  const commonRoutes = [
    { path: "/single-quick-report", element: <SingleQuickReport /> },
    { path: "/single-nwfa-sandfinish", element: <SingleNwfaSandReport /> },
    { path: "/single-nwfa-installation", element: <SingleNwfaInstallation /> },
    { path: "/single-request", element: <SingleInspectionRequest /> },
    { path: "/all-clients", element: <AllClients /> },
    { path: "/industry-standards", element: <IndustryStandards /> },
    { path: "/public-industry-standards", element: <PublicStandards /> },
    { path: "/cover-letter", element: <CoverLetter /> },
    { path: "/report-invoice", element: <SingleInvoice /> },
    { path: "/send-email", element: <CreateEmailReport /> },
    { path: "/single-carpet-basic", element: <SingleCarpetBasic /> },
    { path: "/single-carpet-detailed", element: <SingleCarpetDetailed /> },
    { path: "/single-hardwood-detailed", element: <SingleHardwoodDetailed /> },
    { path: "/single-hardwood-basic", element: <SingleHardwoodBasic /> },
    { path: "/single-tile-report", element: <SingleTile /> },
    { path: "/single-lvt", element: <SingleLvt /> },
    { path: "/single-Laminate", element: <SingleLaminate /> },
    { path: "/NotFoundPage", element: <NotFoundPage /> },
    { path: "/inspector-profile", element: <InspectorProfile /> },
    { path: "/update-profile", element: <UserProfilePage profiles={userDetails?.profiles} /> },
  ];

  const roleBasedRoutes = {
    um_inspector: [
      { path: "/", element: <InspectionRequest /> },
      { path: "/inspection-reports", element: <InspectionRequest /> },
      { path: "/inspection-completed-reports", element: <InspectionRequestCompleted /> },
      { path: "/carpetBasic", element: <CarpetBasic /> },
      { path: "/carpetDetailed", element: <CarpetDetailed /> },
      { path: "/quickReport", element: <QuickReport /> },
      { path: "/quick-report-update", element: <QuickReportUpdate /> },
      { path: "/carpet-basic-update", element: <CarpetBasicUpdate /> },
      { path: "/carpet-detailed-update", element: <CarpetDetailedUpdate /> },
      { path: "/hardwood-basic-update", element: <HardwoodBasicUpdate /> },
      { path: "/lvt-update", element: <LvtUpdate /> },
      { path: "/hardwood-detailed-update", element: <HardwoodDetailedUpdate /> },
      { path: "/Tile", element: <Tile /> },
      { path: "/laminate", element: <Laminate /> },
      { path: "/Lvt", element: <Lvt /> },
      { path: "/nwfa-installation-inspection-report", element: <NwfaInstallationInspectionReport /> },
      { path: "/update-nwfa-inspector-report", element: <NwfaInstallationUpdate /> },
      { path: "/update-nwfa-sand-finish-report", element: <NwfaSandFinishUpdate /> },
      { path: "/HardwoodDetailed", element: <HardwoodDetailed /> },
      { path: "/nwfa-sand-finish-inspection-report", element: <NwfaSandFinishInspectionReport /> },
      { path: "/update-invoice", element: <UpdateInvoice /> },
      { path: "/create-invoice", element: <CreateInvoice /> },
      { path: "/hardwoodBasic", element: <HardSurfaceBasic /> },
      { path: "/create-cover-letter", element: <CreateCoverLetter /> },
      { path: "/update-cover-letter", element: <UpdateCoverLetter /> },
      { path: "/laminate-update", element: <LaminateUpdate /> },
      { path: "/tile-report-update", element: <TileUpdate /> },
      { path: "/attachments", element: <Attachments /> },
      { path: "/all-invoices", element: <AllInvoices /> },
      { path: "/create-new-client", element: <CreateNewClient /> },
      { path: "/add-new-client", element: <Addnewclient /> },
      { path: "/single-view-client", element: <SingleViewClient /> },
      { path: "/view-client-update", element: <UpdateClientView /> },
    ],
    um_dealer: [
      { path: "/", element: <DealerPanel /> },
      { path: "/dealer-panel", element: <DealerPanel /> },
      { path: "/inspection-reports", element: <DealerPanel /> },
    ],
    um_fs_manager: [
      { path: "/", element: <FsmanagerPanel /> },
      { path: "/field-service-manager-panel", element: <FsmanagerPanel /> },
      { path: "/inspection-reports", element: <FsmanagerPanel /> },
      { path: "/field-service-inspectors", element: <InspectorsCRUD /> },
      { path: "/field-service-resolution-form", element: <CustomerResolutionReportContainer /> },

    ],
    um_fs_inspector: [
      { path: "/", element: <FsinspectorPanel /> },
      { path: "/field-service-inspector-panel", element: <FsinspectorPanel /> },
      { path: "/field-service-resolution-form", element: <CustomerResolutionReportContainer /> },
      { path: "/inspection-reports", element: <FsinspectorPanel /> },
      { path: "/create-cover-letter", element: <CreateCoverLetter /> },
      { path: "/update-cover-letter", element: <UpdateCoverLetter /> },
    ],
    "um_claim-analyst": [
      { path: "/", element: <ClaimAnalystPanel /> },
      { path: "/claim-analyst-panel", element: <ClaimAnalystPanel /> },
      { path: "/inspection-reports", element: <ClaimAnalystPanel /> },
      { path: "/send-decision", element: <SendDicision /> },
      { path: "/archived", element: <Archived /> },
      { path: "/unassigned-claims", element: <UnAssignedClaims /> },
      { path: "/field-services", element: <FieldServicesClaims /> },
      { path: "/field-service-resolution-form", element: <CustomerResolutionReportContainer /> },
    ],
    um_manufacturer: [
      { path: "/", element: <Manufacturer /> },
      { path: "/manufacturer-panel", element: <Manufacturer /> },
      { path: "/inspection-reports", element: <Manufacturer /> },
      { path: "/AllClaimAnalysts", element: <AllClaimAnalysts /> },
      { path: "/dealers", element: <Dealers /> },
      { path: "/add-dealer", element: <AddDealer /> },
      { path: "/add-claim-analyst", element: <AddClaimAnalyst /> },
      { path: "/single-claim-analyst", element: <SingleClaimAnalyst /> },
      { path: "/single-dealer", element: <SingleDealer /> },
      { path: "/claim-analyst-update", element: <AllClaimAnalystsUpdate /> },
      { path: "/dealer-update", element: <DealerUpdate /> },
      { path: "/archived", element: <Archived /> },
      { path: "/send-decision", element: <SendDicision /> },
      { path: "/unassigned-claims", element: <UnAssignedClaims /> },
      { path: "/field-services", element: <FieldServicesClaims /> },
      { path: "/field-service-resolution-form", element: <CustomerResolutionReportContainer /> },
    ],
  };

  const renderRoutes = () => {
    const routes = [...commonRoutes, ...(roleBasedRoutes[userRole] || [])];
    return routes.map(({ path, element }) => (
      <Route key={path} exact path={path} element={element} />
    ));
  };

  return <Routes>{renderRoutes()}</Routes>;
};

export default AppRoute;