import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import { Card, Row, Col, Image, Divider, Button, Skeleton,Modal,Form,Radio,List } from "antd";
import PlaceHolderImg from "../../assets/images/logoPlaceholder.png";
import * as NwfaSandFinishService from "../../services/createNwfaService";
import * as ReportServices from "../../services/SingleReport";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormOutlined } from "@ant-design/icons";
import Swal from 'sweetalert2';
import * as setPdfSettingsService from "../../services/PdfSettingsService";

const SingleNwfaInstallationReport = () => {
  var dispatch = useDispatch();
  let navigate = useNavigate();
  const [reportData, setReportData] = useState();
  const [loading, setLoading] = useState(true);
  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false);
  const [savePdfLoading, setSavePdfLoading] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [reportLogos, setReportLogos] = useState(null);
  // const [defaultPdfTemplate, setDefaultPdfTemplate] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    fetchData();
    getPdfTemplateData();
    // setAttachments();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAttachments(reportData?.attachments);
    setReportLogos(reportData?.selectededlogos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData?.attachments, reportData?.selectededlogos]);

  console.warn(setAttachments, setReportLogos);
   const pdfTemplates = {
    1: {
      index: 1,
      template_id: 1,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040610/template_pdf_img_1.png",
    },
    2: {
      index: 2,
      template_id: 2,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040629/template_pdf_img_2.png",
    },
    3: {
      index: 3,
      template_id: 3,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/02/22093235/template_3_img.png",
    },
    4: {
      index: 4,
      template_id: 4,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040619/template_pdf_img_4.png",
    },
    5: {
      index: 5,
      template_id: 5,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21040615/template_pdf_img_5.png",
    },
    6: {
      index: 6,
      template_id: 6,
      template_url:
        "https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/08/28053646/template_pdf_img_6-1.png",
    },
  };
  const handleRadio = (e) => {
    
    setSelectedTemplate(e.target.value);
  }
  const setPdfSettings = (value) => {
    dispatch(setPdfSettingsService.PdfSettingsService(selectedTemplate))
      .then((res) => {
        console.warn({ res });
        Swal.fire({
          icon: 'success',
          title: 'PDF Template selected successfully',
        })
        handleCancel();
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
 
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const getPdfTemplateData = () => {
    setLoading(true);
    dispatch(setPdfSettingsService.getpdfTemplate(selectedTemplate))
    .then((res) => {
      setSelectedTemplate(res?.pdf_template_id);

    })
    .catch((err) => {
      console.info("Get Error Edit State...");
      setLoading(false);
    });
  }
    const getPdf = () => {
    setSavePdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setSavePdfLoading(false);
        
        // After generating the PDF, show the popup to choose the next action
        Swal.fire({
          title: 'PDF Generated Successfully',
          text: 'Please choose your next action:',
          icon: 'success',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Go to Invoice',
          denyButtonText: 'Download PDF',
          cancelButtonText: 'Skip to Cover Letter',
          reverseButtons: true,
          confirmButtonColor: '#0A497B',
          denyButtonColor: '#0A497B',
          cancelButtonColor: '#0A497B',
        }).then((result) => {
          if (result.isConfirmed) {
            if (reportData.invoice_generated === "NO") {
              navigate("/create-invoice/?req_id=" + reportData.req_id);
            } else {
              navigate("/report-invoice/?invoice_id=" + reportData.invoice_id);
            }
          } else if (result.isDenied) {
            // Logic to download the generated PDF
            const link = document.createElement('a');
            link.href = res.data; // Assuming res.data.pdf_url contains the URL of the generated PDF
            link.download = 'GeneratedReport.pdf'; // Specify the file name
            link.click();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            if (reportData.cover_generated === "NO") {
              navigate("/create-cover-letter/?req_id=" + reportData.req_id);
            } else {
              navigate("/cover-letter/?cover_id=" + reportData.cover_id);
            }
          }
        });
  
      })
      .catch((err) => {
        let errorMessage;
  
        if (err.response) {
          errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
        } else if (err.request) {
          errorMessage = "No response received from the server. Please check your network connection.";
        } else {
          errorMessage = `An unexpected error occurred: ${err.message}`;
        }
  
        alert(errorMessage);
        setSavePdfLoading(false);
      });
  };
  const downloadPdf = () => {
    setDownloadPdfLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const report_id = queryParams.get("report_id");
    dispatch(ReportServices.quickReportPdf(report_id))
      .then((res) => {
        console.info("quickReportPdf", res.data);
        setDownloadPdfLoading(false);
        // window.open(res.data, 'download');
        window.open(res.data);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };
  const fetchData = (params = {}) => {
    const queryParams = new URLSearchParams(window.location.search);

    const report_id = queryParams.get("report_id");
    setLoading(true);
    dispatch(NwfaSandFinishService.getSingleNwfaInstaReport(report_id))
      .then((res) => {
        setReportData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };
  const handleNavigate = () => {
    navigate(
      "/update-nwfa-inspector-report/?report_id=" + reportData.report_id
    );
  };
  return (
    <div className="singleReportWrapper">
      <div className="pageHeader">
        <PageHeader title="Inspection Request | Professional Report" />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={24} className="text-end">
              {reportData?.current_user_role === "um_inspector" ? (
                <Button
                  type="success"
                  className="btn inspectionAdd"
                  onClick={() => handleNavigate()}
                  icon={<FormOutlined />}
                >
                  Edit Report
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className="container-xxl">
        <div className="srWrapperInner">
          {loading ? (
            <div className="spinDiv fullHeight">
              {/* <Spin size="large" /> */}
              <Skeleton loading={loading} active avatar>
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
            </Skeleton>
            <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            
            <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
               <br></br>
            <hr/>
            <br></br>
          
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
             </Skeleton>
             <br></br>
             <hr/>
             <br></br>
            <Skeleton loading={loading} active >
              <List.Item.Meta
               />
               </Skeleton>
            </div>
          ) : (
            <Card className="reportCard" id="quickReportContent">
              <div className="reportCardBody">
                <div className="inspectorInfo">
                  <Row gutter={30}>
                    <Col xs={24} md={4}>
                      <div className="inspectorLogo">
                        <Image
                          src={
                            reportData?.logo_url
                              ? reportData.logo_url
                              : PlaceHolderImg
                          }
                          className="ing-fluid"
                          alt="InspectorLogo"
                          preview={false}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={20}>
                      <div className="inspectorDetails">
                        <div className="inspecDetailHead">
                          <h5 className="inspcTitle">
                            Inspection Request | Professional Report
                          </h5>
                        </div>
                        <div className="inspcInfo">
                          <p className="orgName">
                            {reportData?.organization_name_ins}
                          </p>
                          <p className="inspcData">
                            <span className="inspcSpan">
                              {reportData?.insp_fullname_ins}
                            </span>

                            <span className="inspcSpan">
                              {reportData?.address_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Office- {reportData?.phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              Cell- {reportData?.company_phone_number_ins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.websiteins}
                            </span>
                            {"|"}
                            <span className="inspcSpan">
                              {reportData?.email_ins}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Inspection Details */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    General Information
                  </Divider>
                  <Card className="innerCard">
                    <div className="reportData">
                      <Row gutter={16}>
                        <Col xs={24} md={6}>
                          <div className="formGroup">
                            <label>Claim Number</label>
                            <p>{reportData?.claim_number}</p>
                          </div>
                        </Col>
                        {reportData?.inspection_name ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspector Name</label>
                              <p>{reportData?.inspection_name}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {reportData?.h_bi_ins_date ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspection Date</label>
                              <p>{reportData?.h_bi_ins_date}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {reportData?.inspection_cp ? (
                          <Col xs={24} md={6}>
                            <div className="formGroup">
                              <label>Inspector CP #</label>
                              <p>{reportData?.inspection_cp}</p>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </Card>
                </div>
                {/* Commissioning Party Information */}
                {reportData.comm_party_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Commissioning Party Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.comm_party_drop ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Commissioning Party</label>
                                {reportData?.comm_party_drop === "Other" ? (
                                  <p>{reportData?.comm_party_drop_other}</p>
                                ) : (
                                  <p>{reportData?.comm_party_drop}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.commisioning_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                           {reportData?.commisioning_party_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.commisioning_party_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.commisioning_party_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.commisioning_party_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.commisioning_party_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.commisioning_party_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.commisioning_party_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.commisioning_party_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>
                                  {reportData?.commisioning_party_contact_number}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.commisioning_party_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.commisioning_party_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Homeowner/Building Information */}
                {reportData.homeowner_building_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Homeowner/Building Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.homeowner_Name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.homeowner_Name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.homeowner_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.homeowner_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.homeowner_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.homeowner_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.homeowner_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.homeowne_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.homeowne_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.homeowner_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>{reportData?.homeowner_contact_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.homeowner_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.homeowner_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Flooring Contractor Information */}
                {reportData.flooring_cont_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Flooring Contractor Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.flooring_contractor_Name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.flooring_contractor_Name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.flooring_contractor_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.flooring_contractor_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.flooring_contractor_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.flooring_contractor_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.flooring_contractor_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.flooring_contractor_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>
                                  {reportData?.flooring_contractor_contact_number}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooring_contractor_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.flooring_contractor_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Manufacturer Information */}
                {reportData.manuf_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Manufacturer Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.manufacturer_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.manufacturer_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                           {reportData?.manufacturer_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.manufacturer_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.manufacturer_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.manufacturer_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.manufacturer_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                            {reportData?.manufacturer_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.manufacturer_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.manufacturer_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_contact_info ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Main Contact Info</label>
                                <p>{reportData?.manufacturer_contact_info}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_title ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Title</label>
                                <p>{reportData?.manufacturer_title}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>{reportData?.manufacturer_contact_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.manufacturer_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.manufacturer_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Retailer Information */}
                {reportData.retailer_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Retailer Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.retailer_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.retailer_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_address ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Address</label>
                                <p>{reportData?.retailer_address}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Street Name/Number</label>
                                <p>{reportData?.retailer_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_city ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>City</label>
                                <p>{reportData?.retailer_city}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_state ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>State</label>
                                <p>{reportData?.retailer_state}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                             {reportData?.retailer_country ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Country</label>
                                <p>{reportData?.retailer_country}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_zip ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Zip</label>
                                <p>{reportData?.retailer_zip}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_contact_info ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Main Contact Info</label>
                                <p>{reportData?.retailer_contact_info}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_title ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Title</label>
                                <p>{reportData?.retailer_title}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_contact_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Contact Number</label>
                                <p>{reportData?.retailer_contact_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.retailer_email ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Email</label>
                                <p>{reportData?.retailer_email}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Jobsite Information */}
                {reportData.jobsite_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Jobsite Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.jobsite_date ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Jobsite Visit Date</label>
                                <p>{reportData?.jobsite_date}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.jobsite_time ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Time</label>
                                <p>{reportData?.jobsite_time} {reportData?.time_type}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.property_dropdown ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Property Type</label>
                                <p>{reportData?.property_dropdown}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.building_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Type</label>
                                {reportData?.building_type === "Other" ? (
                                  <p>{reportData?.build_type_other}</p>
                                ) : (
                                  <p>{reportData?.building_type}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.building_style ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building Style</label>
                                {reportData?.building_style === "Other" ? (
                                  <p>{reportData?.other_building_style}</p>
                                ) : (
                                  <p>{reportData?.building_style}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.jobsite__age_building ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Age of Building</label>
                                <p>{reportData?.jobsite__age_building}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.h_bui_cons === "HideThisField" || reportData?.h_bui_cons === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>New Construction?</label>
                                  <p>
                                    {reportData?.h_bui_cons === "Yes" || reportData?.h_bui_cons === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_cons === "No" || reportData?.h_bui_cons === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.closing_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Closing Date</label>
                                    <p>{reportData?.closing_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}


                              {reportData?.moving_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Move in Date</label>
                                    <p>{reportData?.moving_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {reportData?.operational_date ? (
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>HVAC Operational Date</label>
                                    <p>{reportData?.operational_date}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                                  </>
                                  )}
                            </>
                          )
                        }
                          {reportData?.h_bui_occupied === "HideThisField" || reportData?.h_bui_occupied === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                  <label>Occupied</label>
                                  <p>
                                    {reportData?.h_bui_occupied === "Yes" || reportData?.h_bui_occupied === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_bui_occupied === "No" || reportData?.h_bui_occupied === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.adults ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Adults</label>
                                        <p>{reportData?.adults}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.childrens ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Children</label>
                                        <p>{reportData?.childrens}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  {reportData?.pets ? (
                                    <Col xs={24} md={6}>
                                      <div className="formGroup">
                                        <label>Pets</label>
                                        <p>{reportData?.pets}</p>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                  )}
                            </>
                         )}
                        </Row>
                        <Row gutter={16}>
                          {reportData?.concerns ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Statement of Concern</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.concerns,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Claim History */}
                {reportData.claim_history === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Claim History
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.date_sold_to_end_user ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Sold to End-User</label>
                                <p>{reportData?.date_sold_to_end_user}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_deliver_to_jobsite ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Delivered to Jobsite</label>
                                <p>{reportData?.date_deliver_to_jobsite}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_of_installation ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date of Installation</label>
                                <p>{reportData?.date_of_installation}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_completion ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date of Completion</label>
                                <p>{reportData?.date_completion}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_occupied ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Occupied</label>
                                <p>{reportData?.date_occupied}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_completion_first ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Complaint First Noticed</label>
                                <p>{reportData?.date_completion_first}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_reported ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Reported</label>
                                <p>{reportData?.date_reported}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.reported_to ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Reported to</label>
                                <p>{reportData?.reported_to}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.clean_product ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Cleaning Products/Methods Used</label>
                                <p>{reportData?.clean_product}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.how_often ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>How Often Cleaned?</label>
                                <p>{reportData?.how_often}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.unusual_events ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Unusual Events</label>
                                <p>{reportData?.unusual_events}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Jobsite Condition at Installation */}
                {reportData.jobsite_condition_ins_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Jobsite Condition at Installation
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.temp_during_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>
                                  Temperature (Interior) During Install
                                </label>
                                <p>{reportData?.temp_during_ins}{reportData?.temp_f_c}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rh_during_ins ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>RH (Interior) During Install</label>
                                <p>{reportData?.rh_during_ins}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          

                          {reportData?.hvac_oper_during_storage === "HideThisField" || reportData?.hvac_oper_during_storage === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>HVAC Operational During Storage</label>
                                  <p>{reportData?.hvac_oper_during_storage}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.hvac_oper_during_install === "HideThisField" || reportData?.hvac_oper_during_install === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>HVAC Operational During Install</label>
                                  <p>{reportData?.hvac_oper_during_install}</p>
                                </div>
                              </Col>
                          )}
                          
                        </Row>
                        <Row gutter={16}>
                          {reportData?.process_acclimation ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Process of Acclimation</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.process_acclimation,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                        <Row gutter={16}>
                          {reportData?.loc_des_stor_area ? (
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Location and Description of Storage Area at
                                Jobsite</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.loc_des_stor_area,
                                  }}
                                ></p>
                              </div>
                              
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Moisture Content at Installations */}
                {reportData.mois_content_ins_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Moisture Content at Installations
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.mois_meter_type ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Moisture Meter Type</label>
                                <p>{reportData?.mois_meter_type}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.mois_cont_name ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Name</label>
                                <p>{reportData?.mois_cont_name}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.mois_cont_model ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Model</label>
                                <p>{reportData?.mois_cont_model}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.mois_species_cor ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Meter Species Correction</label>
                                <p>{reportData?.mois_species_cor}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                        <Row gutter={16}>
                          {reportData?.mois_content_reading ? (
                            <Col xs={24} md={24}>
                              
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Moisture Content Readings (include a range,
                                  average, and number of tests performed)</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.mois_content_reading,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Installation Method */}
                {reportData.installation_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Installation Method
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.car_i2_inst_type ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Installation Method</label>
                                <p>{reportData?.car_i2_inst_type}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.car_i2_inst_type === "Nail/staple" ? (
                            <>
                              {/* <Row gutter={16}> */}
                              {reportData?.inst_method_fastner_type ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Fastener Type</label>
                                    <p>{reportData?.inst_method_fastner_type}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_length ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Length</label>
                                    <p>{reportData?.inst_method_length}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_gauge ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Gauge</label>
                                    <p>{reportData?.inst_method_gauge}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_pne_air_pres ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Pneumatic Air Pressure Setting</label>
                                    <p>{reportData?.inst_method_pne_air_pres}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_nailers_used_floor_install ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Number of Nailers Used on the Floor Install
                                    </label>
                                    <p>
                                      {
                                        reportData?.inst_method_nailers_used_floor_install
                                      }
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_type_brand_nailers ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Type Brand of Nailers</label>
                                    <p>
                                      {reportData?.inst_method_type_brand_nailers}
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_type_underlayment_used ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Type of Underlayment Used</label>
                                    <p>
                                      {
                                        reportData?.inst_method_type_underlayment_used
                                      }
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                              {reportData?.inst_metho_floot_measured_flat === "HideThisField" || reportData?.inst_metho_floot_measured_flat === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Floor Measured For Flat</label>
                                      <p>{reportData?.inst_metho_floot_measured_flat}</p>
                                    </div>
                                  </Col>
                              )}
                              {reportData?.inst_metho_flatn_measured ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Method Flatness Measured</label>
                                    <p>{reportData?.inst_metho_flatn_measured}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_Toler ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Tolerance 1/4' in 10' (+/-)</label>
                                    <p>{reportData?.inst_method_Toler}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_Toler3 ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Tolerance 3/16' in 6' (+/-)</label>
                                    <p>{reportData?.inst_method_Toler3}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_type_movement_with_floor_sys ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Movement within Flooring System</label>
                                    <p>
                                      {
                                        reportData?.inst_method_type_movement_with_floor_sys
                                      }
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.inst_method_add_ins ? (
                                <Col xs={24} md={24}>
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                    <label>Additional Installation Information</label>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: reportData?.inst_method_add_ins,
                                      }}
                                    ></p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {/* </Row> */}
                            </>
                          ) : (
                            ""
                          )}
                          {reportData?.car_i2_inst_type === "Glue down" ? (
                            <>
                              {/* <Row gutter={16}> */}
                              {reportData?.adhesive_sys_used ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Adhesive System Used (type/brand)
                                    </label>
                                    <p>{reportData?.adhesive_sys_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.spread_rate ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Spread Rate</label>
                                    <p>{reportData?.spread_rate}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.mois_cont_sys_used ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Moisture Control System Used (type/brand)
                                    </label>
                                    <p>{reportData?.mois_cont_sys_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.application_method_glue_down ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Application Method</label>
                                    <p>{reportData?.application_method_glue_down}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.type_trowel_used ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Type of Trowel Used</label>
                                    <p>{reportData?.type_trowel_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.trowel_used ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Trowel Used</label>
                                    <p>{reportData?.trowel_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                  <label>Number of Trowels Used</label>
                                  <p>{reportData?.num_trowel_used}</p>
                                </div>
                              </Col>
                              
                              {reportData?.subfloor_measured_flat === "HideThisField" || reportData?.subfloor_measured_flat === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Subfloor Measured for Flat</label>
                                      <p>{reportData?.subfloor_measured_flat}</p>
                                    </div>
                                  </Col>
                              )}
                              {reportData?.meth_flat_meas ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Method Flatness Measured</label>
                                    <p>{reportData?.meth_flat_meas}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.glue_tol ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Tolerance 3/16' in 10' (+/-)</label>
                                    <p>{reportData?.glue_tol}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.glu_tolerance ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Tolerance 1/8' in 8' (+/-)</label>
                                    <p>{reportData?.glu_tolerance}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.age_con ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Age of Concrete</label>
                                    <p>{reportData?.age_con}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.con_joints ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Construction Joints Present</label>
                                    <p>{reportData?.con_joints}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.glu_loc ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Location(s)</label>
                                    <p>{reportData?.glu_loc}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.glue_type ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Type</label>
                                    <p>{reportData?.glue_type}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.glu_met_add ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Method Addressed at Installation
                                    </label>
                                    <p>{reportData?.glu_met_add}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.glu_mov_with_flr_sys ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Movement Within Flooring System</label>
                                    <p>{reportData?.glu_mov_with_flr_sys}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.glu_am_ins_floor ? (
                                <Col xs={24} md={12}>
                                  <div className="formGroup">
                                    <label>
                                      Amount of Time Before Traffic Was Allowed on
                                      Installed Floor
                                    </label>
                                    <p>{reportData?.glu_am_ins_floor}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.glu_add_inst_inf ? (
                                <Col xs={24} md={24}>
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                    <label>Additional Installation Information</label>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: reportData?.glu_add_inst_inf,
                                      }}
                                    ></p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {/* </Row> */}
                            </>
                          ) : (
                            ""
                          )}
                          {reportData?.car_i2_inst_type === "Floating" ? (
                            <>
                              {reportData?.float_ins_met ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Floating Installation Method</label>
                                    <p>{reportData?.float_ins_met}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.float_adhesive_used ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Adhesive Used</label>
                                    <p>{reportData?.float_adhesive_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.float_per_expa ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Perimeter Expansion</label>
                                    <p>{reportData?.float_per_expa}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.float_underlayment_used ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Type of Underlayment Used</label>
                                    <p>{reportData?.float_underlayment_used}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                              {reportData?.float_floor_measured_for_flat === "HideThisField" || reportData?.float_floor_measured_for_flat === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Floor Measured For Flat</label>
                                      <p>{reportData?.float_floor_measured_for_flat}</p>
                                    </div>
                                  </Col>
                              )}
                              {reportData?.float_metho_flatn_measured ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Method Flatness Measured</label>
                                    <p>
                                      {reportData?.float_metho_flatn_measured}
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.flo_Toler ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Tolerance 3/16' in 10' (+/-)</label>
                                    <p>{reportData?.flo_Toler}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.float_Toler3 ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Tolerance 1/8' in 6' (+/-)</label>
                                    <p>{reportData?.float_Toler3}</p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.float_movement_with_floor_sys ? (
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Movement within Flooring System</label>
                                    <p>
                                      {reportData?.float_movement_with_floor_sys}
                                    </p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              {reportData?.float_add_ins ? (
                                <Col xs={24} md={24}>
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                    <label>Additional Installation Information</label>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: reportData?.float_add_ins,
                                      }}
                                    ></p>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Commissioning Party Comments */}
                {reportData.comm_party_comm_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Commissioning Party Comments
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Commissioning Party Comments</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.float_comm_party_comm,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* End-User Comments */}
                {reportData.enduser_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      End-User Comments
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>End-User Comments</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.float_end_user_comm,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Flooring Contractor Comments */}
                {reportData.flooring_contractor_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Flooring Contractor Comments
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Flooring Contractor Comments</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.float_floor_con_comm,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Other Involved Party Comments */}
                {reportData.other_invol_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Other Involved Party Comments
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Other Involved Party Comments</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.float_oth_inv_comm,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}

                {/* Concerns */}
                {reportData.concern === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Concerns
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.concern_con_exi_flr ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Condition of Existing Floor</label>
                                {reportData?.concern_con_exi_flr === "Other" ? (
                                  <p>{reportData?.concern_con_exi_flr_other}</p>
                                ) : (
                                  <p>{reportData?.concern_con_exi_flr}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Flooring */}
                {reportData.flooring_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Flooring
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.concern_manuf ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Manufacturer</label>
                                <p>{reportData?.concern_manuf}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.concern_spec_drop ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Species</label>
                                <p>{reportData?.concern_spec_drop}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.flooringtype ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Flooring Type</label>
                                {reportData?.flooringtype === "Other" ? (
                                  <p>{reportData?.concern_spec_drop_other}</p>
                                ) : (
                                  <p>{reportData?.flooringtype}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.flooringtype === "Plank" ? (
                            <>
                              {reportData?.concern_spec_drop_width ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Width of Plank</label>

                                    <p>{reportData?.concern_spec_drop_width}</p>

                                  </div>
                                </Col> : ""}
                            </>
                          ) : (
                            ""
                          )}
                          {reportData?.concern_floor_thick ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Flooring Thickness</label>
                                <p>{reportData?.concern_floor_thick}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.concern_add_inf_flr_con ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>
                                  Additional Info on Flooring Container
                                </label>
                                <p>{reportData?.concern_add_inf_flr_con}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.concern_prod_date ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Production Date</label>
                                <p>{reportData?.concern_prod_date}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.produc_lot_number ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Production Lot Number</label>
                                <p>{reportData?.produc_lot_number}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.sqft_per_cont ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Amount per Container</label>
                                <p>{reportData?.sqft_per_cont} {reportData?.measure_type15}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.total_sqft ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Total Material Installed</label>
                                <p>{reportData?.total_sqft} {reportData?.measure_type16}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.square_foot_deli ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Square Footage Delivered</label>
                                <p>{reportData?.square_foot_deli}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.per_flo_aff ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>% of Floor Affected</label>
                                <p>{reportData?.per_flo_aff}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.app_over_radiant_heat === "HideThisField" || reportData?.app_over_radiant_heat === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Approved Over Radiant Heat</label>
                                  <p>{reportData?.app_over_radiant_heat}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.app_below_grade === "HideThisField" || reportData?.app_below_grade === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Approved Below Grade</label>
                                  <p>{reportData?.app_below_grade}</p>
                                </div>
                              </Col>
                          )}
                          
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Rooms Being Inspected */}
                {reportData.rooms_being_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Rooms Being Inspected
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.rooms_entry ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Entry</label>
                                <p>{reportData?.rooms_entry}  {reportData?.measure_type1}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_formal ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Formal</label>
                                <p>{reportData?.rooms_formal} {reportData?.formal_temp}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_kit ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Kitchen</label>
                                <p>{reportData?.rooms_kit} {reportData?.measure_type3}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_study ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Study</label>
                                <p>{reportData?.rooms_study} {reportData?.measure_type4}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_hall ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Hall(s)</label>
                                <p>{reportData?.rooms_hall} {reportData?.measure_type5}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_library ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Library</label>
                                <p>{reportData?.rooms_library} {reportData?.measure_type6}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_great_room ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Great Room</label>
                                <p>{reportData?.rooms_great_room} {reportData?.measure_type7}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_living_room ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Living Room</label>
                                <p>{reportData?.rooms_living_room} {reportData?.measure_type9}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_dining_roo ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Dining Room</label>
                                <p>{reportData?.rooms_dining_roo} {reportData?.measure_type10}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_nook ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Nook</label>
                                <p>{reportData?.rooms_nook} {reportData?.measure_type11}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rooms_part ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Pantry</label>
                                <p>{reportData?.rooms_part} {reportData?.measure_type12}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.powderBath ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Powder Bath</label>
                                <p>{reportData?.powderBath} {reportData?.powder_bath}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.bathArea[0]['bath_area'] !== "" ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Bath</label>
                                {reportData?.bathArea.map((single) => (
                                  <p>
                                    {single.bath_area} {single.bath_area_unit}
                                  </p>
                                ))}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.masterbedroom ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Master Bedroom</label>
                                <p>{reportData?.masterbedroom} {reportData?.measure_type14}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.masterBedroomArea[0]['master_bedroom_area'] !== "" ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Bed</label>
                                {reportData?.masterBedroomArea.map((single) => (
                                  <p>
                                    {single.master_bedroom_area}{" "}
                                    {single.master_bedroom_area_unit}
                                  </p>
                                ))}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.other_rooms ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Other Rooms</label>
                                <p>{reportData?.other_rooms}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* New Construction Info */}
                {reportData.new_constructed_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      New Construction Info
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.date_home_completed ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date Home Completed</label>
                                <p>{reportData?.date_home_completed}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.date_hvac_fully_oper ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Date HVAC Fully Operational</label>
                                <p>{reportData?.date_hvac_fully_oper}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Exterior Evaluation */}
                {reportData.exterior_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Exterior Evaluation
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.exter_home_face ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Home Faces</label>
                                <p>{reportData?.exter_home_face}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rele_lot_street ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Relation of Lot to Street</label>
                                <p>{reportData?.rele_lot_street}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.rele_lot_neigh ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Relation of Lot to Neighbors</label>
                                <p>{reportData?.rele_lot_neigh}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.lot_drainage_away_foun === "HideThisField" || reportData?.lot_drainage_away_foun === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Lot Drainage Away from Foundation</label>
                                  <p>{reportData?.lot_drainage_away_foun}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.slope_al_meas ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Slope Angle Measurement</label>
                                <p>{reportData?.slope_al_meas}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.buil_over ?
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Building is Over</label>
                                <p>{reportData?.buil_over}</p>
                              </div>
                            </Col> : ""}
                          
                          {reportData?.water_sour_swimm === "HideThisField" || reportData?.water_sour_swimm === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Water Source/Swimming Pool Nearby</label>
                                  <p>{reportData?.water_sour_swimm}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.prox_to_stru ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Proximity to Structure</label>
                                <p>{reportData?.prox_to_stru}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.gutters_down === "HideThisField" || reportData?.gutters_down === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Gutters/Downspouts</label>
                                  <p>{reportData?.gutters_down}</p>
                                </div>
                              </Col>
                          )}
                          
                          {reportData?.roof_overhang === "HideThisField" || reportData?.roof_overhang === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Roof Overhang</label>
                                  <p>{reportData?.roof_overhang}</p>
                                </div>
                              </Col>
                          )}
                          
                          {reportData?.soil_damp === "HideThisField" || reportData?.soil_damp === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Soil Damp at Foundation</label>
                                  <p>{reportData?.soil_damp}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.wind_wells ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Window-Wells</label>
                                <p>
                                  {reportData?.wind_wells}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.signs_mois === "HideThisField" || reportData?.signs_mois === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Signs of Moisture</label>
                                  <p>{reportData?.signs_mois}</p>
                                </div>
                              </Col>
                          )}
                          
                          {reportData?.sprinklers_irri === "HideThisField" || reportData?.sprinklers_irri === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Sprinklers/Irrigation</label>
                                  <p>{reportData?.sprinklers_irri}</p>
                                </div>
                              </Col>
                          )}
                          
                          {reportData?.Esta_lands === "HideThisField" || reportData?.Esta_lands === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Established Landscape</label>
                                  <p>{reportData?.Esta_lands}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.visible_cracks === "HideThisField" || reportData?.visible_cracks === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Visible Cracks in Foundation</label>
                                  <p>{reportData?.visible_cracks}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.visible_exter === "HideThisField" || reportData?.visible_exter === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>Visible Exterior Damage</label>
                                  <p>{reportData?.visible_exter}</p>
                                </div>
                              </Col>
                          )}
                          {reportData?.shrubs_flowers === "HideThisField" || reportData?.shrubs_flowers === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={6}>
                                <div className="formGroup">
                                <label>
                                  Shrubs/Flowers/Planter Boxes at Foundation
                                </label>
                                  <p>{reportData?.shrubs_flowers}</p>
                                </div>
                              </Col>
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* HVAC Evaluation */}
                {reportData.hvac_evalution_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      HVAC Evaluation
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>

                          {reportData?.type_heating_sys ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Type of Heating System</label>
                                {reportData?.type_heating_sys === "Other" ? (
                                  <>
                                    <p>{reportData?.type_heating_sys_oth}</p>

                                  </>
                                ) : (
                                  <p>{reportData?.type_heating_sys}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.loc_heating ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Location of Heating</label>
                                <p>{reportData?.loc_heating}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.type_cooling_sys ? (
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Type Of Cooling System</label>
                                {reportData?.type_cooling_sys === "Other" ? (
                                  <>
                                    <p>{reportData?.air_on_other}</p>
                                  </>
                                ) : (
                                  <p>{reportData?.type_cooling_sys}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.hvac_sys_oper === "HideThisField" || reportData?.hvac_sys_oper === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>HVAC System Operating</label>
                                  <p>{reportData?.hvac_sys_oper}</p>
                                </div>
                              </Col>
                          )}

                          {reportData?.h_dehu_syst === "HideThisField" || reportData?.h_dehu_syst === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                  <label>Humidification/Dehumidification System</label>
                                  <p>
                                    {reportData?.h_dehu_syst === "Yes" || reportData?.h_dehu_syst === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.h_dehu_syst === "No" || reportData?.h_dehu_syst === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              
                              
                                {reportData?.humidification_dehum === "HideThisField" || reportData?.humidification_dehum === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>
                                        Is Humidification/Dehumidification System
                                        Operating?
                                      </label>
                                  <p>
                                    {reportData?.humidification_dehum === "Yes" || reportData?.humidification_dehum === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.humidification_dehum === "No" || reportData?.humidification_dehum === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                  {reportData?.data_logger === "HideThisField" || reportData?.data_logger === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Data Logger</label>
                                          <p>{reportData?.data_logger}</p>
                                        </div>
                                      </Col>
                                  )}
                                  {reportData?.ther_control_sett ?
                                    <Col xs={24} md={8}>
                                      <div className="formGroup">
                                        <label>Thermostat Control Settings</label>
                                        <p>{reportData?.ther_control_sett}</p>
                                      </div>
                                    </Col> : ""}

                                  {reportData?.programma === "HideThisField" || reportData?.programma === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>
                                          Is Humidification/Dehumidification System
                                          Programmable?
                                        </label>
                                          <p>{reportData?.programma}</p>
                                        </div>
                                      </Col>
                                  )}
                                      </>
                                        )}
                                    </>
                                  )
                                }

                              {reportData?.date_last_serv ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Date of Last Service (if known)</label>
                                    <p>{reportData?.date_last_serv}</p>
                                  </div>
                                </Col> : ""}
                              
                              {reportData?.has_floor_full_cyc === "HideThisField" || reportData?.has_floor_full_cyc === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>
                                        Has Floor Been Through Full Cycle of Seasons?
                                        </label>
                                          <p>{reportData?.has_floor_full_cyc}</p>
                                        </div>
                                      </Col>
                              )}
                                  </>
                                  )}
                              </>
                            )
                          }
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Special Considerations */}
                {reportData.special_consideration_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Special Considerations
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.traff_use ?
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Traffic Use</label>
                                <p>{reportData?.traff_use}</p>
                              </div>
                            </Col> : ""}
                          {reportData?.other_trades ?
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Other Trades | Schedules prior to, during or after
                                installation</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.other_trades,
                                  }}
                                ></p>
                              </div>
                            </Col> : ""}
                          {reportData?.any_special_or_unique_use ?
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Any Special or Unique Use</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.any_special_or_unique_use,
                                  }}
                                ></p>
                              </div>
                            </Col> : ""}
                          {reportData?.audible_noises ?
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Audible Noises-Locations</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.audible_noises,
                                  }}
                                ></p>
                              </div>
                            </Col> : ""}
                          {reportData?.desc_sound ?
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Description of Sound</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.desc_sound,
                                  }}
                                ></p>
                              </div>
                            </Col> : ""}
                          {reportData?.fac_finish_issue ?
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Factory Finish Issues</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.fac_finish_issue,
                                  }}
                                ></p>
                              </div>
                            </Col> : ""}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Subfloor Evaluation */}
                {reportData.subfloor_evalution_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Subfloor Evaluation
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          
                          {reportData?.create_slab_evaluation === "HideThisField" || reportData?.create_slab_evaluation === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                  <label>Concrete Slab Evaluation</label>
                                    <p>
                                      {reportData?.create_slab_evaluation === "Yes" || reportData?.create_slab_evaluation === "yes"
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                </Col>
                                {reportData?.create_slab_evaluation === "No" || reportData?.create_slab_evaluation === "no" ? (
                                                        ""
                                                      ) : (
                                                        <>
                              
                              {reportData?.Normal_weight_con ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Normal Weight Concrete (min 3000 psi)
                                    </label>
                                    <p>{reportData?.Normal_weight_con}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.light_weight_gypsum ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Light Weight Gypsum Concrete</label>
                                    <p>{reportData?.light_weight_gypsum}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.pre_tension ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Pre-Tension/Post-Tension Slab</label>
                                    <p>{reportData?.pre_tension}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.Moist_ret_use_slab ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Moisture Retarder used Below Slab</label>
                                    <p>{reportData?.Moist_ret_use_slab}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.subfloor_rela_slab_surface ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Relation of Slab Surface to Exterior Soil
                                      Line(inches)
                                    </label>
                                    <p>{reportData?.subfloor_rela_slab_surface}</p>
                                  </div>
                                </Col> : ""}
                                </>
                                )}
                              </>
                            )}
                          
                            {reportData?.wood_subfloor_evaluations === "HideThisField" || reportData?.wood_subfloor_evaluations === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                  <label>Wood Subfloor Evaluation</label>
                                    <p>
                                      {reportData?.wood_subfloor_evaluations === "Yes" || reportData?.wood_subfloor_evaluations === "yes"
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                </Col>
                                {reportData?.wood_subfloor_evaluations === "No" || reportData?.wood_subfloor_evaluations === "no" ? (
                                                        ""
                                                      ) : (
                                                        <>
                              {reportData?.type_wood_subfloor ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Type of Wood Subfloor</label>
                                    {reportData?.type_wood_subfloor === "Other" ? (
                                      <p>{reportData?.type_wood_subfloor_other}</p>
                                    ) : (
                                      <p>{reportData?.type_wood_subfloor}</p>
                                    )}
                                  </div>
                                </Col> : ""}
                              {reportData?.joist_spa ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Joist Spacing</label>
                                    <p>{reportData?.joist_spa}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.jois_ty ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Joist Type</label>
                                    <p>{reportData?.jois_ty}</p>
                                  </div>
                                </Col> : ""}
                              
                                {reportData?.visible_rot === "HideThisField" || reportData?.visible_rot === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Visible Rot</label>
                                          <p>{reportData?.visible_rot}</p>
                                        </div>
                                      </Col>
                                  )}
                                </>
                                )}
                              </>
                           )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Below Flooring System Evaluation */}
                {reportData.below_flooring_sys_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Below Flooring System Evaluation
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.crawl_space_evelu ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Crawl Space Evaluation</label>
                                {reportData?.crawl_space_evelu === "Other" ? (
                                  <p>{reportData?.crawl_space_evelu_other}</p>
                                ) : (
                                  <p>{reportData?.crawl_space_evelu}</p>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.seam_overlapped ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Seams Overlapped (inches)</label>
                                <p>
                                  {reportData?.seam_overlapped}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.seams_sealed === "HideThisField" || reportData?.seams_sealed === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Seams Sealed</label>
                                          <p>{reportData?.seams_sealed}</p>
                                        </div>
                                      </Col>
                            )}
                          {reportData?.meth_sealing ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Method of Sealing</label>
                                <p>
                                  {reportData?.meth_sealing}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {reportData?.vents_prese === "HideThisField" || reportData?.vents_prese === "" ? (
                                      ""
                                    ) : (
                              <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Vents Present</label>
                                    <p>{reportData?.vents_prese}</p>
                                  </div>
                              </Col>
                          )}
                          {reportData?.num_vents ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Number of Vents</label>
                                <p>{reportData?.num_vents}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {reportData?.opens_closed ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Vents Open/Closed</label>
                                <p>{reportData?.opens_closed}</p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.cond_present === "HideThisField" || reportData?.cond_present === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Condensation Present</label>
                                          <p>{reportData?.cond_present}</p>
                                        </div>
                                      </Col>
                            )}
                          {reportData?.stand_water_present === "HideThisField" || reportData?.stand_water_present === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Standing Water Present</label>
                                          <p>{reportData?.stand_water_present}</p>
                                        </div>
                                      </Col>
                            )}
                          {reportData?.Alkali_dis === "HideThisField" || reportData?.Alkali_dis === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Alkali/Discoloration on Foundation Walls</label>
                                          <p>{reportData?.Alkali_dis}</p>
                                        </div>
                                      </Col>
                            )}
                          {reportData?.vapor_retarder_present === "HideThisField" || reportData?.vapor_retarder_present === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Vapor Retarder Present</label>
                                          <p>{reportData?.vapor_retarder_present}</p>
                                        </div>
                                      </Col>
                            )}
                          {reportData?.per_ground_cover ? (
                            <Col xs={24} md={6}>
                              <div className="formGroup">
                                <label>Percent of Ground Covered(%)</label>
                                <p>
                                  {reportData?.per_ground_cover}
                                </p>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          
                          {reportData?.plast_tap === "HideThisField" || reportData?.plast_tap === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Plastic Taped up Foundation Walls</label>
                                          <p>{reportData?.plast_tap}</p>
                                        </div>
                                      </Col>
                            )}
                          
                          {reportData?.ins_bw_joists === "HideThisField" || reportData?.ins_bw_joists === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Insulation between Joists</label>
                                          <p>{reportData?.ins_bw_joists}</p>
                                        </div>
                                      </Col>
                            )}
                          {reportData?.ins_found_wall === "HideThisField" || reportData?.ins_found_wall === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Insulation on Foundation Walls</label>
                                          <p>{reportData?.ins_found_wall}</p>
                                        </div>
                                      </Col>
                            )}
                          
                          {reportData?.humidistat_ins === "HideThisField" || reportData?.humidistat_ins === "" ? (
                                      ""
                                    ) : (
                                      <Col xs={24} md={8}>
                                        <div className="formGroup">
                                        <label>Humidistat Installed</label>
                                          <p>{reportData?.humidistat_ins}</p>
                                        </div>
                                      </Col>
                            )}
                         
                            {reportData?.basement_evaluation === "HideThisField" || reportData?.basement_evaluation === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Basement Evaluation</label>
                                  <p>
                                    {reportData?.basement_evaluation === "Yes" || reportData?.basement_evaluation === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.basement_evaluation === "No" || reportData?.basement_evaluation === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                          
                              {reportData?.base_finish ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Basement Status</label>
                                    <p>{reportData?.base_finish}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.base_finish === "Finished" ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Date Completed</label>
                                    <p>{reportData?.date_completed}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.specify_side_below ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>
                                      Specify Side(s) below ground level
                                    </label>
                                    <p>{reportData?.specify_side_below.join(",")}</p>
                                  </div>
                                </Col> : ""}

                              
                              {reportData?.wall_cracks_present === "HideThisField" || reportData?.wall_cracks_present === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Wall Cracks Present</label>
                                      <p>{reportData?.wall_cracks_present}</p>
                                    </div>
                                  </Col>
                              )}
                              {reportData?.locat ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Location of Wall Cracks</label>
                                    <p>{reportData?.locat}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.sign_mois_dam ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Signs of Moisture Damage</label>
                                    <p>{reportData?.sign_mois_dam}</p>
                                  </div>
                                </Col> : ""}
                              
                              {reportData?.walk_out === "HideThisField" || reportData?.walk_out === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Walk Out</label>
                                      <p>{reportData?.walk_out}</p>
                                    </div>
                                  </Col>
                              )}
                              
                              {reportData?.sump_pump === "HideThisField" || reportData?.sump_pump === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Sump Pump</label>
                                      <p>{reportData?.sump_pump}</p>
                                    </div>
                                  </Col>
                              )}
                              
                              {reportData?.opera === "HideThisField" || reportData?.opera === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Is Sump Pump Operating?</label>
                                      <p>{reportData?.opera}</p>
                                    </div>
                                  </Col>
                              )}
                              
                              {reportData?.hvac_vents_open_basement === "HideThisField" || reportData?.hvac_vents_open_basement === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>HVAC Vents Open to Basement</label>
                                      <p>{reportData?.hvac_vents_open_basement}</p>
                                    </div>
                                  </Col>
                              )}
                              
                              {reportData?.peeling_paint === "HideThisField" || reportData?.peeling_paint === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Peeling Paint</label>
                                      <p>{reportData?.peeling_paint}</p>
                                    </div>
                                  </Col>
                              )}
                              
                              {reportData?.floor_stains === "HideThisField" || reportData?.floor_stains === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Floor Stains</label>
                                      <p>{reportData?.floor_stains}</p>
                                    </div>
                                  </Col>
                              )}
                              
                              {reportData?.rusty_nails === "HideThisField" || reportData?.rusty_nails === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Rusty Nails</label>
                                      <p>{reportData?.rusty_nails}</p>
                                    </div>
                                  </Col>
                              )}
                              {reportData?.gapped_trim === "HideThisField" || reportData?.gapped_trim === "" ? (
                                  ""
                                ) : (
                                  <Col xs={24} md={6}>
                                    <div className="formGroup">
                                    <label>Gapped Trim or Moldings</label>
                                      <p>{reportData?.gapped_trim}</p>
                                    </div>
                                  </Col>
                              )}
                              {reportData?.summ_phy_des ?
                                <Col xs={24} md={24}>
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                    <label>Summary of Physical Description</label>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: reportData?.summ_phy_des,
                                      }}
                                    ></p>
                                  </div>
                                </Col> : ""}
                                </>
                                    )}
                                  </>
                                )}
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : ""}
                {/* Jobsite Conditions at Inspection */}
                {reportData.jobsite_con_ins === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Jobsite Conditions at Inspection
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          
                            {reportData?.avr_mois_cont === "HideThisField" || reportData?.avr_mois_cont === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>
                                    Average Moisture Content at Inspection
                                  </label>
                                  <p>
                                    {reportData?.avr_mois_cont === "Yes" || reportData?.avr_mois_cont === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.avr_mois_cont === "No" || reportData?.avr_mois_cont === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              
                              {reportData?.avr_floor ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Floor</label>
                                    <p>{reportData?.avr_floor}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_top ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Top</label>
                                    <p>{reportData?.avr_top}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_back ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Back</label>
                                    <p>{reportData?.avr_back}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_moist_meter ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Moisture Meter Type</label>
                                    <p>{reportData?.avr_moist_meter}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_name ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Name</label>
                                    <p>{reportData?.avr_name}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_model ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Model</label>
                                    <p>{reportData?.avr_model}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_meter ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Meter Species Correction</label>
                                    <p>{reportData?.avr_meter}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_num_reading ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Number of Readings</label>
                                    <p>{reportData?.avr_num_reading}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_hight_read ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>High Reading</label>
                                    <p>{reportData?.avr_hight_read}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_low_read ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Low Reading</label>
                                    <p>{reportData?.avr_low_read}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.avr_loca_high ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Locations of High Readings</label>
                                    <p>{reportData?.avr_loca_high}</p>
                                  </div>
                                </Col> : ""}
                                </>
                                    )}
                                  </>
                                )}
                          

                          {reportData?.hvac_tsti === "HideThisField" || reportData?.hvac_tsti === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>HVAC Testing</label>
                                  <p>
                                    {reportData?.hvac_tsti === "Yes" || reportData?.hvac_tsti === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.hvac_tsti === "No" || reportData?.hvac_tsti === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              
                              {reportData?.Temp_hvac ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Temperature</label>
                                    <p>
                                      {reportData?.Temp_hvac}{reportData?.measure_type17}
                                    </p>
                                  </div>
                                </Col> : ""}
                              {reportData?.rel_humidity ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Relative Humidity(%)</label>
                                    <p>{reportData?.rel_humidity}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.m_use ?
                                <Col xs={24} md={6}>
                                  <div className="formGroup">
                                    <label>Meter Used</label>
                                    <p>{reportData?.m_use}</p>
                                  </div>
                                </Col> : ""}
                                </>
                                    )}
                                  </>
                                )}
                          {reportData?.mois_t_res ?
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Moisture Test Results (include an average and number of tests performed)</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.mois_t_res,
                                  }}
                                ></p>
                              </div>
                            </Col> : ""}
                          {reportData?.com_readings ?
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Comparison Readings (Baseboards, Trim, Door,
                                  Cabinets, etc)</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.com_readings,
                                  }}
                                ></p>
                              </div>
                            </Col> : ""}
                          {reportData?.oth_moi_test_conducted ?
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Other Moisture Tests Conducted</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.oth_moi_test_conducted,
                                  }}
                                ></p>
                              </div>
                            </Col> : ""}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Floor Tests */}
                {reportData.floor_test_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Floor Tests
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          {reportData?.avg_meas_indi_boa ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>
                                  Average Measurement of Individual Boards Face Width
                                </label>
                                <p>{reportData?.avg_meas_indi_boa}</p>
                              </div>
                            </Col> : ""}
                          {reportData?.meas_acr_board ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Measurement Across 10 Boards</label>
                                <p>{reportData?.meas_acr_board}</p>
                              </div>
                            </Col> : " "}
                          {reportData?.twe_boards ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>20 Boards</label>
                                <p>{reportData?.twe_boards}</p>
                              </div>
                            </Col> : ""}
                          {reportData?.avverage ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Average</label>
                                <p>{reportData?.avverage}</p>
                              </div>
                            </Col> : ""}
                          {reportData?.avr_m_gaps ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Average Measurement of Gaps</label>
                                <p>{reportData?.avr_m_gaps}</p>
                              </div>
                            </Col> : ""}
                          {reportData?.cupp_meas ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Cupping Measurements</label>
                                <p>{reportData?.cupp_meas}</p>
                              </div>
                            </Col> : " "}
                          {reportData?.bucking_meas ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Buckling Measurements</label>
                                <p>{reportData?.bucking_meas}</p>
                              </div>
                            </Col> : " "}
                          
                          {reportData?.does_patr_issue === "HideThisField" || reportData?.does_patr_issue === "" ? (
                              ""
                            ) : (
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                <label>
                                  Does pattern of issue convey a relationship
                                  with building mechanical systems or foundation
                                  or slab cracks and/or settlement?
                                </label>
                                  <p>{reportData?.does_patr_issue}</p>
                                </div>
                              </Col>
                          )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Substrate Testing */}
                {reportData.substrate_testing_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Substrate Testing
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          
                            {reportData?.Co_s_test === "HideThisField" || reportData?.Co_s_test === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Concrete Slab Testing</label>
                                  <p>
                                    {reportData?.Co_s_test === "Yes" || reportData?.Co_s_test === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.Co_s_test === "No" || reportData?.Co_s_test === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              
                              {reportData?.Mois_req_flo_man ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Moisture Test(s) Required by Flooring
                                      Manufacturer
                                    </label>
                                    {reportData?.Mois_req_flo_man === "Other" ? (
                                      <p>{reportData?.Mois_req_flo_man_other}</p>
                                    ) : (
                                      <p>{reportData?.Mois_req_flo_man.join(",")}</p>
                                    )}
                                  </div>
                                </Col> : ""}
                                {reportData?.res_req ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Results of Required Test(s)
                                    </label>
                                    <p>{reportData?.res_req}</p>
                                  </div>
                                </Col> : " "}
                              {reportData?.num_of_tets ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Number of Tests
                                    </label>
                                    <p>{reportData?.num_of_tets}</p>
                                  </div>
                                </Col> : ""}
                             
                              {reportData?.l_of_tets ?
                                <Col xs={24} md={24}>
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Location of Tests by Flooring Manufacturer</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.l_of_tets,
                                  }}
                                ></p>
                              </div>
                                </Col> : " "}
                              {reportData?.Mois_req_adhesive_manu ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Moisture Test(s) Required by Adhesive
                                      Manufacturer
                                    </label>
                                    {reportData?.Mois_req_adhesive_manu ===
                                      "Other" ? (
                                      <p>
                                        {reportData?.Mois_req_adhesive_man_other}
                                      </p>
                                    ) : (
                                      <p>{reportData?.Mois_req_adhesive_manu}</p>
                                    )}
                                  </div>
                                </Col> : ""}
                              {reportData?.res_req_adhes_man ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Results of Required Test(s)
                                    </label>
                                    <p>{reportData?.res_req_adhes_man}</p>
                                  </div>
                                </Col> : " "}
                              {reportData?.num_of_tets_adh_manu ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Number of Tests
                                    </label>
                                    <p>{reportData?.num_of_tets_adh_manu}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.l_of_tets_adhe_manu ?
                                <Col xs={24} md={24}>
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Location of Tests</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.l_of_tets_adhe_manu,
                                  }}
                                ></p>
                              </div>
                                </Col> : ""}
                              {reportData?.other_moisture_test_cond ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Other Moisture Test(s) Conducted
                                    </label>
                                    <p>{reportData?.other_moisture_test_cond}</p>
                                  </div>
                                </Col> : " "}
                              {reportData?.res_rt ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Results of Required Test(s)</label>
                                    <p>{reportData?.res_rt}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.locati_of_tests ?
                                <Col xs={24} md={24}>
                                  
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Location of Tests</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.locati_of_tests,
                                  }}
                                ></p>
                              </div>
                                </Col> : ""}
                                
                                 </>
                                 )}
                               </>
                             )}
                          
                            {reportData?.wood_sub_test === "HideThisField" || reportData?.wood_sub_test === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Wood Subfloor Testing</label>
                                  <p>
                                    {reportData?.wood_sub_test === "Yes" || reportData?.wood_sub_test === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.wood_sub_test === "No" || reportData?.wood_sub_test === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                            
                              {reportData?.wood_mois_cont_read ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Moisture Content Readings</label>
                                    <p>{reportData?.wood_mois_cont_read}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.wood_sub_low_read_hight_read ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>High Reading</label>
                                    <p>
                                      {reportData?.wood_sub_low_read_hight_read}
                                    </p>
                                  </div>
                                </Col> : ""}
                              {reportData?.wood_sub_low_read ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Low Reading</label>
                                    <p>{reportData?.wood_sub_low_read}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.wood_mois_t_res ?
                                <Col xs={24} md={12}>
                                  <div className="formGroup">
                                    <label>Moisture Test Results (include an average and number of tests performed)</label>
                                    <p>{reportData?.wood_mois_t_res}</p>
                                  </div>
                                </Col> : ""}
                              
                                {reportData?.wood_mois_on_top === "HideThisField" || reportData?.wood_mois_on_top === "" ? (
                                    ""
                                  ) : (
                                    <Col xs={24} md={8}>
                                      <div className="formGroup">
                                      <label>Moisture on Top</label>
                                        <p>{reportData?.wood_mois_on_top}</p>
                                      </div>
                                    </Col>
                                )}
                              
                                {reportData?.wood_mois_in_core === "HideThisField" || reportData?.wood_mois_in_core === "" ? (
                                    ""
                                  ) : (
                                    <Col xs={24} md={8}>
                                      <div className="formGroup">
                                      <label>Moisture in Core</label>
                                        <p>{reportData?.wood_mois_in_core}</p>
                                      </div>
                                    </Col>
                                )}
                                {reportData?.wood_mois_on_underside === "HideThisField" || reportData?.wood_mois_on_underside === "" ? (
                                    ""
                                  ) : (
                                    <Col xs={24} md={8}>
                                      <div className="formGroup">
                                      <label>Moisture on Underside</label>
                                        <p>{reportData?.wood_mois_on_underside}</p>
                                      </div>
                                    </Col>
                                )}
                              {reportData?.mois_name ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Meter Name</label>
                                    <p>{reportData?.mois_name}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.mois_mtrtype ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Moisture Meter Type</label>
                                    <p>{reportData?.mois_mtrtype}</p>
                                  </div>
                                </Col> : ""}
                                {reportData?.mois_mtr_usedsettings ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Moisture Meter Used</label>
                                    <p>{reportData?.mois_mtr_usedsettings}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.mois_mtr_settings ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Meter Setting</label>
                                    <p>{reportData?.mois_mtr_settings}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.mois_mod ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Model</label>
                                    <p>{reportData?.mois_mod}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.joismoiscont ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Joist Moisture Content</label>
                                    <p>{reportData?.joismoiscont}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.com_reading ?
                                <Col xs={24} md={24}>
                                  
                                  <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Comparison Readings(baseboards, trim, door,
                                  cabinets, etc.)</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.com_reading,
                                  }}
                                ></p>
                              </div>
                                </Col> : ""}
                                </>
                                 )}
                               </>
                             )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Below Flooring System Testing */}
                {reportData.below_flooring_system_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Below Flooring System Testing
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          
                          {reportData?.crawlspace_test === "HideThisField" || reportData?.crawlspace_test === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Crawl Space Testing</label>
                                  <p>
                                    {reportData?.crawlspace_test === "Yes" || reportData?.crawlspace_test === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.crawlspace_test === "No" || reportData?.crawlspace_test === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                    
                              {reportData?.crawl_temp ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Temperature</label>
                                    <p>{reportData?.crawl_temp}{reportData?.measure_type13}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.crawl_rel_humidity ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Relative Humidity(%)</label>
                                    <p>{reportData?.crawl_rel_humidity}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.crawl_m_use ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Meter Used</label>
                                    <p>{reportData?.crawl_m_use}</p>
                                  </div>
                                </Col>
                                : ""}
                              {reportData?.dist_groun_un ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Distance from Ground to Underside of
                                      Joists(inches/cm)
                                    </label>
                                    <p>{reportData?.dist_groun_un}</p>
                                  </div>
                                </Col> : ""}
                               
                          {reportData?.j_m_cont ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>Joist Moisture Content</label>
                                <p>{reportData?.j_m_cont}</p>
                              </div>
                            </Col> : ""}
                          {reportData?.m_under_sub ?
                            <Col xs={24} md={8}>
                              <div className="formGroup">
                                <label>
                                  Moisture Content Underside Subfloor
                                </label>
                                <p>{reportData?.m_under_sub}</p>
                              </div>
                            </Col> : ""}
                            {reportData?.below_meter_used ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Meter Used</label>
                                    <p>{reportData?.below_meter_used}</p>
                                  </div>
                                </Col>
                                : ""}
                                 </>
                                    )}
                              </>
                            )}
                          
                            {reportData?.baseTesting === "HideThisField" || reportData?.baseTesting === "" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              <Col xs={24} md={8}>
                                <div className="formGroup">
                                <label>Basement Testing</label>
                                  <p>
                                    {reportData?.baseTesting === "Yes" || reportData?.baseTesting === "yes"
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              {reportData?.baseTesting === "No" || reportData?.baseTesting === "no" ? (
                                                      ""
                                                    ) : (
                                                      <>
                              
                              {reportData?.basementTemper ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Temperature in Basement</label>
                                    <p>{reportData?.basementTemper}{reportData?.measure_type2}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.basemnt_rel_humidity ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Relative Humidity(%) in Basement
                                    </label>
                                    <p>{reportData?.basemnt_rel_humidity}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.basmnt_m_use ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Meter Used for Basement</label>
                                    <p>{reportData?.basmnt_m_use}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.basemnt_j_m_cont ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Joist Moisture Content in Basement
                                    </label>
                                    <p>{reportData?.basemnt_j_m_cont}</p>
                                  </div>
                                </Col> : ""}
                                {reportData?.bsmntm_under_sub ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>
                                      Moisture Content Underside Subfloor in Basement
                                    </label>
                                    <p>{reportData?.bsmntm_under_sub}</p>
                                  </div>
                                </Col> : ""}
                              {reportData?.bsmntmee_us ?
                                <Col xs={24} md={8}>
                                  <div className="formGroup">
                                    <label>Meter Used in Basement</label>
                                    <p>{reportData?.bsmntmee_us}</p>
                                  </div>
                                </Col> : ""}
                                </>
                                    )}
                              </>
                            )}
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Additional Testing Information */}
                {reportData.additional_testing_info_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Additional Testing Information
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Additional Testing Information</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.add_testing_information,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Applied Industry Standards */}
                {reportData.apply_industry_stndr_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Applied Industry Standards
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Applied Industry Standards</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.apply_industry_stndr,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>
                ) : ""}
                {/* Conclusion/Cause */}
                {reportData.conclusion_cause_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Conclusion/Cause
                    </Divider>
                    <Card className="innerCard">
                      <div className="reportData">
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="formGroup sunEditorEditable sun-editor-editable">
                              <label>Conclusion/Cause</label>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: reportData?.conclusion_cause,
                                }}
                              ></p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>) : ""}
                {/* Footer */}
                {reportData.footer_toggle === "yes" ? (
                  <div className="inspectionDetails">
                    <Divider orientation="left" plain>
                      Footer
                    </Divider>
                    <Card className="innerCard">
                      <div className="inspectionDetails">
                        {reportData?.signurl ? (
                          <>
                            <Divider orientation="left" plain>
                              Upload Signature
                            </Divider>
                            <div className="reportData">
                              <Row gutter={16}>
                                <Col xs={24} md={24}>
                                  <div className="formGroup customSignatureImage">
                                    <Image
                                      src={reportData?.signurl}
                                      alt="image1"
                                      preview={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* Footer text */}
                      {reportData.footer_text_info === "yes" ? (
                      <div className="inspectionDetails">
                        <Divider orientation="left" plain>
                          Footer Text
                        </Divider>
                        <div className="reportData">
                          <Row gutter={16}>
                            <Col xs={24} md={24}>
                              <div className="formGroup sunEditorEditable sun-editor-editable">
                                <label>Footer Text</label>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: reportData?.footer_text,
                                  }}
                                ></p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div> ) : "" }
                    </Card>
                  </div>) : ""}
                {/* Add Attachements */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Add Attachments
                  </Divider>
                  <div className="reportData">
                    <Row gutter={16}>
                        <Image.PreviewGroup>
                      {attachments &&
                        Object.keys(attachments).map((keyName, index) => (
                          <Col xs={6} md={6} key={index}>
                            <div className="formGroup customFormGroup">
                              <Image
                                src={attachments[keyName].url}
                                alt="image1"
                                className="img-fluid"
                                preview={true}
                              />
                              <p className="count">
                                {attachments[keyName].id}
                              </p>
                              <p className="descriptions">
                                {attachments[keyName].desc}
                              </p>
                            </div>
                          </Col>
                        ))}
                        </Image.PreviewGroup>
                    </Row>
                  </div>
                </div>

                {/* CERTIFICATION */}
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    CERTIFICATION
                  </Divider>
                  <div className="reportData footerReportData">
                    <Row justify="center">
                      {reportLogos &&
                        Object.keys(reportLogos).map((keyName, index) => (
                          <Col xs={2} md={2} key="" className="logoCol">
                            <div className="logoDiv">
                              <div className="innerDiv">
                                <img
                                  src={reportLogos[keyName].logo_url}
                                  alt="not found"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
                {/*   Private Notes */}
                {reportData.private_notes_togg === "yes" ? (
                <div className="inspectionDetails">
                  <Divider orientation="left" plain>
                    Private Notes
                  </Divider>
                  <div className="reportData">
                    <Row gutter={16}>
                      <Col xs={24} md={24}>
                        <div className="formGroup sunEditorEditable sun-editor-editable">
                          <label>Private Notes</label>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: reportData?.private_notes,
                            }}
                          ></p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div> ) : "" }
                <Row gutter={16} className="pt-3">
                  <Col xs={24} md={24} className="text-center">
                  <Button
                        className="btnPrimary"
                        onClick={showModal}
                      >
                        Select Your PDF Layout
                      </Button>
                    {reportData?.current_user_role === "um_manufacturer" ||
                      reportData?.current_user_role === "um_claim-analyst" ||
                      reportData?.current_user_role === "um_dealer" ? (
                      <Button
                        className="btnPrimary"
                        onClick={() => downloadPdf()}
                        loading={downloadPdfLoading}
                      >
                        Download PDF
                      </Button>
                    ) : reportData?.current_user_role === "um_inspector" ? (
                      <>
                        <Button
                          className="btnPrimary"
                          onClick={() => downloadPdf()}
                          loading={downloadPdfLoading}
                        >
                          Download PDF
                        </Button>
                        <Button
                          className="btnPrimary"
                          onClick={() => getPdf()}
                          loading={savePdfLoading}
                        >
                          Save and Continue
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          )}
        </div>
      </div>
      <Modal
        title={"Choose PDF Template."}
        centered
        open={isModalOpen}
        className="inspectionCategoryModal industryModal customIndustryModal"
        width={1000}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="pdfTemplateform"
          layout="vertical"
          onFinish={setPdfSettings}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          
          <div className="modalContent pdfTemp">
            <div className="formGroup">
              <Radio.Group
                name="radiogroup"
                onChange={handleRadio}
                defaultValue={parseInt(selectedTemplate)}>

                  {Object.keys(pdfTemplates).map((keyName, index) => (
                    <Radio.Button
                      value={pdfTemplates[keyName].template_id}
                      key={index}
                      
                    >
                      <Image
                        src={pdfTemplates[keyName].template_url}
                        className="img-fluid"
                        alt="logos"
                        preview={false}
                      />
                  </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
          </div>
          <Button className="btnPrimary" htmlType="submit">
            Save and Exit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default SingleNwfaInstallationReport;
