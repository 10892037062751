import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import {
  AppstoreOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
  UserOutlined,
  FileUnknownOutlined,
  SolutionOutlined
} from '@ant-design/icons';

const itemsForManufacturer = [
  {
    label: 'Inspections',
    key: 'inspections',
    icon: <FileSearchOutlined />,
    children: [
      {
        label: 'Open Inspections',
        key: 'open-inspections',
        icon: <FileSearchOutlined />,
      },
      {
        label: 'Archived Claims',
        key: 'archived-claims',
        icon: <FolderOpenOutlined />,
      },
      {
        label: 'UnAssigned Claims',
        key: 'unassigned-claims',
        icon: <FileUnknownOutlined />,
        condition: 'is_mohawk',
      },
      {
        label: 'Field Services',
        key: 'field-services',
        icon: <SolutionOutlined />,
        condition: 'is_mohawk',
      },
    ],
  },
  {
    label: 'Claim Analysts',
    key: 'claim-analysts',
    icon: <UserOutlined />,
  },
  {
    label: 'Distributors or Dealers',
    key: 'dealers',
    icon: <AppstoreOutlined />,
  },
  {
    label: 'Industry Standards',
    key: 'industry-standards',
    icon: <FileTextOutlined />,
  },
];

const itemsForClaimAnalyst = [
  {
    label: 'Inspections',
    key: 'inspections',
    icon: <FileSearchOutlined />,
    children: [
      {
        label: 'All Claims',
        key: 'all-claims',
        icon: <FileSearchOutlined />,
      },
      {
        label: 'Archived Claims',
        key: 'archived-claims',
        icon: <FolderOpenOutlined />,
      },
      {
        label: 'UnAssigned Claims',
        key: 'unassigned-claims',
        icon: <UserOutlined />,
        condition: 'is_mohawk',
      },
      {
        label: 'Field Services',
        key: 'field-services',
        icon: <SolutionOutlined />,
        condition: 'is_mohawk',
      },
    ],
  },
  {
    label: 'Industry Standards',
    key: 'industry-standards',
    icon: <FileTextOutlined />,
  },
];

const itemsForInspector = [
  {
    label: 'Inspections',
    key: 'inspections',
    icon: <FileSearchOutlined />,
    children: [
      {
        label: 'Open Inspections',
        key: 'open-inspections',
        icon: <FileSearchOutlined />,
      },
      {
        label: 'Completed Reports',
        key: 'completed-reports',
        icon: <FileDoneOutlined />,
      },
    ],
  },
  {
    label: 'All Clients',
    key: 'all-clients',
    icon: <AppstoreOutlined />,
    disabledCondition: 'limited_access', // Added this line
  },
  {
    label: 'All Invoices',
    key: 'all-invoices',
    icon: <AppstoreOutlined />,
    disabledCondition: 'limited_access', // Added this line
  },
  {
    label: 'Industry Standards',
    key: 'industry-standards',
    icon: <FileTextOutlined />,
    disabledCondition: 'limited_access', // Added this line
  },
];

const getMenuItems = (role, isMohawk, isLimitedAccess) => {
  const menuItems = {
    um_manufacturer: itemsForManufacturer,
    'um_claim-analyst': itemsForClaimAnalyst,
    um_inspector: itemsForInspector,
  };

  // Filter out items based on condition (like is_mohawk)
  return menuItems[role].map((item) => ({
    ...item,
    disabled: item.disabledCondition && role === 'um_inspector' && isLimitedAccess,
    children: item.children?.filter(
      (child) => !child.condition || (child.condition && isMohawk)
    ),
  }));
};

const ButtonNavigation = () => {
  const [current, setCurrent] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector((state) => state.User.userDetails);
  const isLimitedAccess = userDetails?.limited_access;


  useEffect(() => {
    const pathToKeyMap = {

      '/inspection-reports': 'open-inspections',

      '/inspection-completed-reports': 'completed-reports',

      '/claim-analyst-panel': 'all-claims',

      '/archived': 'archived-claims',

      '/unassigned-claims': 'unassigned-claims',

      '/field-services': 'field-services',

      '/AllClaimAnalysts': 'claim-analysts',

      '/dealers': 'dealers',

      '/industry-standards': 'industry-standards',

      '/all-clients': 'all-clients',

      '/all-invoices': 'all-invoices',

    };



    const currentPath = location.pathname;

    setCurrent(pathToKeyMap[currentPath]);

  }, [location.pathname]);

  const onClick = (e) => {
    setCurrent(e.key);
    switch (e.key) {
      case 'open-inspections':
        navigate('/inspection-reports');
        break;
      case 'completed-reports':
        navigate('/inspection-completed-reports');
        break;
      case 'all-claims':
        navigate('/claim-analyst-panel');
        break;
      case 'archived-claims':
        navigate('/archived');
        break;
      case 'unassigned-claims':
        navigate('/unassigned-claims');
        break;
      case 'claim-analysts':
        navigate('/AllClaimAnalysts');
        break;
      case 'dealers':
        navigate('/dealers');
        break;
      case 'industry-standards':
        navigate('/industry-standards');
        break;
      case 'all-clients':
        navigate('/all-clients');
        break;
      case 'all-invoices':
        navigate('/all-invoices');
        break;
      case 'field-services':
        navigate('/field-services');
        break;
      default:
        break;
    }
  };

  const items = getMenuItems(userDetails?.user_role, userDetails?.is_mohawk, isLimitedAccess);

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
      style={{ fontSize: '1rem' }}
    />
  );
};

export default ButtonNavigation;
