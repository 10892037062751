import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Collapse,
  Input,
  Card,
  Form,
  DatePicker,
  Cascader,
  Select,
  Button,
  Spin,
  Empty,
  Divider,
  Space,
  Checkbox,
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import * as getClientbyIdService from '../../../services/getClientbyID';
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
import { useDispatch } from 'react-redux';
import SunEditor from 'suneditor-react';
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link

} from "suneditor/src/plugins";
import axios from "axios";
import { MEDIA_UPLOAD_URL } from "../../../Helper";
const { Panel } = Collapse;
const { Option } = Select;

const timeampm = [
  {
    value: 'AM',
    label: 'AM',
  },
  {
    value: 'PM',
    label: 'PM',
  },
];

const FirstStep = (props) => {
  console.warn('FirstStep', props);
  var dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isOpenBtn, setIsOpenBtn] = useState(true);
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [homeOwnerData, setHomeOwnerData] = useState({});
  const [flooringcontractorData, setflooringcontractorData] = useState({});
  const [manufacturerData, setManufacturerData] = useState({});
  const [reatailerData, setReatailerData] = useState({});
  const [editorStatConcern, setStatConcern] = useState("");
  const DateFormat = 'MM-DD-YYYY';


  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: "150px",
    imageHeight: "150px",
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule", "lineHeight"],
      ["outdent", "indent"], ["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };
  const onStatConcernChange = (editorData) => {
    setStatConcern(editorData);
    props.values.concerns = editorData;
    props.handleEditor(editorData, "concerns");
  };



  const onImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData()
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    formData.append('file', files[0])
    axios.post(MEDIA_UPLOAD_URL, formData, config)
      .then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.data.source_url,
              "name": files[0].name,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response)
      })
  }


  const addClient = () => {
    setIsOpenClient(false);
    setIsOpenBtn(true);
    form.setFieldsValue({
      comm_party_drop: '',
      commisioning_name: '',
      commisioning_party_address: '',
      commisioning_party_street: '',
      commisioning_party_country: '',
      commisioning_party_city: '',
      commisioning_party_state: '',
      commisioning_party_zip: '',
      commisioning_party_contact_number: '',
      commisioning_party_email: '',
    });
  };
  const getFlooringContractorData = (e) => {
    let value = props.values;
    let newval = {
      commisioning_name: value.commisioning_name,
      commisioning_party_street: value.commisioning_party_street,
      commisioning_party_country: value.commisioning_party_country,
      commisioning_party_city: value.commisioning_party_city,
      commisioning_party_state: value.commisioning_party_state,
      commisioning_party_zip: value.commisioning_party_zip,
      commisioning_party_contact_number:
        value.commisioning_party_contact_number,
      commisioning_party_email: value.commisioning_party_email,
    };
    if (e.target.checked) {
      setflooringcontractorData(newval);
      console.info('flooringContractorData', newval);
      props.values.flooring_contractor_Name = newval.commisioning_name;
      props.values.flooring_contractor_street = newval.commisioning_party_street;
      props.values.flooring_contractor_country =
        newval.commisioning_party_country;
      props.values.flooring_contractor_city = newval.commisioning_party_city;
      props.values.flooring_contractor_state = newval.commisioning_party_state;
      props.values.flooring_contractor_zip = newval.commisioning_party_zip;
      props.values.flooring_contractor_contact_number =
        newval.commisioning_party_contact_number;
      props.values.flooring_contractor_email = newval.commisioning_party_email;

      props.values.flooringContractorData = true;
    } else {
      setflooringcontractorData({});

      props.values.flooring_contractor_Name = "";
      props.values.flooring_contractor_street = "";
      props.values.flooring_contractor_country = "";
      props.values.flooring_contractor_city = "";
      props.values.flooring_contractor_state = "";
      props.values.flooring_contractor_zip = "";
      props.values.flooring_contractor_contact_number = "";
      props.values.flooring_contractor_email = "";
      props.values.flooringContractorData = false;
    }

  };
  const getManufacturerData = (e) => {
    let value = props.values;
    let newval = {
      commisioning_name: value.commisioning_name,
      commisioning_party_street: value.commisioning_party_street,
      commisioning_party_country: value.commisioning_party_country,
      commisioning_party_city: value.commisioning_party_city,
      commisioning_party_state: value.commisioning_party_state,
      commisioning_party_zip: value.commisioning_party_zip,
      commisioning_party_contact_number:
        value.commisioning_party_contact_number,
      commisioning_party_email: value.commisioning_party_email,
    };
    if (e.target.checked) {
      setManufacturerData(newval);
      console.info('ManufacturerData', newval);
      props.values.manufacturer_name = newval.commisioning_name;
      props.values.manufacturer_street = newval.commisioning_party_street;
      props.values.manufacturer_country = newval.commisioning_party_country;
      props.values.manufacturer_city = newval.commisioning_party_city;
      props.values.manufacturer_state = newval.commisioning_party_state;
      props.values.manufacturer_zip = newval.commisioning_party_zip;
      props.values.manufacturer_email = newval.commisioning_party_email;
      props.values.manufacturer_contact_number =
        newval.commisioning_party_contact_number;
      props.values.manufacturerData = true;
    } else {
      setManufacturerData({});
      props.values.manufacturer_name = "";
      props.values.manufacturer_street = "";
      props.values.manufacturer_country = "";
      props.values.manufacturer_city = "";
      props.values.manufacturer_state = "";
      props.values.manufacturer_zip = "";
      props.values.manufacturer_email = "";
      props.values.manufacturer_contact_number = "";
      props.values.manufacturerData = false;
    }

  };
  const getReatailerData = (e) => {
    let value = props.values;
    let newval = {
      commisioning_name: value.commisioning_name,
      commisioning_party_street: value.commisioning_party_street,
      commisioning_party_country: value.commisioning_party_country,
      commisioning_party_city: value.commisioning_party_city,
      commisioning_party_state: value.commisioning_party_state,
      commisioning_party_zip: value.commisioning_party_zip,
      commisioning_party_contact_number:
        value.commisioning_party_contact_number,
      commisioning_party_email: value.commisioning_party_email,
    };
    if (e.target.checked) {
      setReatailerData(newval);
      console.info('ReatailerData', newval);
      props.values.retailer_name = newval.commisioning_name;
      props.values.retailer_street = newval.commisioning_party_street;
      props.values.retailer_country = newval.commisioning_party_country;
      props.values.retailer_city = newval.commisioning_party_city;
      props.values.retailer_state = newval.commisioning_party_state;
      props.values.retailer_zip = newval.commisioning_party_zip;
      props.values.retailer_contact_number =
        newval.commisioning_party_contact_number;
      props.values.retailer_email = newval.commisioning_party_email;
      props.values.reatailerData = true;
    } else {
      setReatailerData({});
      props.values.retailer_name = "";
      props.values.retailer_street = "";
      props.values.retailer_country = "";
      props.values.retailer_city = "";
      props.values.retailer_state = "";
      props.values.retailer_zip = "";
      props.values.retailer_contact_number = "";
      props.values.retailer_email = "";
      props.values.reatailerData = false;
    }

  };
  const getHomeOwnerData = (e) => {
    let value = props.values;
    let newval = {
      commisioning_name: value.commisioning_name,
      commisioning_party_street: value.commisioning_party_street,
      commisioning_party_country: value.commisioning_party_country,
      commisioning_party_city: value.commisioning_party_city,
      commisioning_party_state: value.commisioning_party_state,
      commisioning_party_zip: value.commisioning_party_zip,
      commisioning_party_contact_number:
        value.commisioning_party_contact_number,

      commisioning_party_email: value.commisioning_party_email,
    };
    if (e.target.checked) {
      setHomeOwnerData(newval);
      props.values.homeowner_Name = newval.commisioning_name;
      props.values.homeowner_street = newval.commisioning_party_street;
      props.values.homeowne_country = newval.commisioning_party_country;
      props.values.homeowner_city = newval.commisioning_party_city;
      props.values.homeowner_state = newval.commisioning_party_state;
      props.values.homeowner_zip = newval.commisioning_party_zip;
      props.values.homeowner_contact_number =
        newval.commisioning_party_contact_number;
      props.values.homeowner_email = newval.commisioning_party_email;
      props.values.commishomeowner = true;
    } else {
      setHomeOwnerData({});
      props.values.homeowner_Name = "";
      props.values.homeowner_street = "";
      props.values.homeowne_country = "";
      props.values.homeowner_city = "";
      props.values.homeowner_state = "";
      props.values.homeowner_zip = "";
      props.values.homeowner_contact_number = "";
      props.values.homeowner_email = "";
      props.values.commishomeowner = false;
    }
  };

  const getClientbyId = (key) => {
    setIsOpenBtn(false);
    props.values.client_select_drop = key;
    dispatch(getClientbyIdService.getClientbyId(key))
      .then((res) => {
        console.log('apidataget', res);
        form.setFieldsValue({
          commisioning_name: res.name,
          commisioning_party_address: res.address,
          commisioning_party_street: res.street,
          commisioning_party_country: res.country,
          comm_party_drop: res.comm_party_drop,
          commisioning_party_email: res.email,
          commisioning_party_city: res.city,
          commisioning_party_state: res.states,
          commisioning_party_zip: res.zip_code,
          commisioning_party_contact_number: res.phone_personal,
        });
        props.handleFill({
          commisioning_name: res.name,
          commisioning_party_address: res.address,
          commisioning_party_street: res.street,
          commisioning_party_country: res.country,
          comm_party_drop: res.comm_party_drop,
          commisioning_party_email: res.email,
          commisioning_party_state: res.states,
          commisioning_party_city: res.city,
          commisioning_party_zip: res.zip_code,
          commisioning_party_contact_number: res.phone_personal,
        });
      })
      .catch((err) => {
        // Extract and display a meaningful error message
        let errorMessage;

        if (err.response) {
          // The request was made and the server responded with a status code outside of the 2xx range
          errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
        } else if (err.request) {
          // The request was made but no response was received
          errorMessage = "No response received from the server. Please check your network connection.";
        } else {
          // Something else happened while setting up the request
          errorMessage = `An unexpected error occurred: ${err.message}`;
        }

        // Display the error message in an alert or use a UI notification component
        alert(errorMessage);
      });
  };
  useEffect(() => {
    props.values.concerns
      ? setStatConcern(props.values.concerns)
      : setStatConcern("");
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideClient = () => {
    setIsOpenBtn(false);
  };
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {props.loading ? (
        <div className="spinDiv fullHeight">
          <Spin size="large" />
        </div>
      ) : (
        <Form
          name="report"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Card className="reportCard">
            <div className="reportCardBody">
              <div className="formSection">
                <div className="inspectionSection">
                  <div className="inspecHead">
                    <h4 className="formGroupTitle">General Information</h4>
                  </div>
                  <div className="inspecContent">
                    <Row gutter={16}>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item label="Claim Number" name="claim_number">
                            <Input
                              placeholder="Claim Number"
                              className="formControl"
                              onChange={props.handleChange('claim_number')}
                              defaultValue={props.values.claim_number}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Inspector Name"
                            name="inspection_name"
                          >
                            <Input
                              placeholder="Inspector Name"
                              className="formControl"
                              onChange={props.handleChange('inspection_name')}
                              defaultValue={props.values.inspection_name}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Inspection Date"
                            name="h_bi_ins_date"
                          >
                            <Input.Group compact className="mt-0">
                              <Input
                                placeholder="Inspection Date"
                                className="formControl"
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    'h_bi_ins_date',
                                    'text'
                                  )
                                }
                                value={props.values.h_bi_ins_date}
                                onFocus={() => props.setCalOpen(!props.calOpen)}
                                onKeyUp={() => props.setCalOpen(false)}
                                onBlur={() => props.setCalOpen(false)}
                              />

                              <Button
                                className="btnAddOn"
                                icon={<CalendarOutlined />}
                                onClick={() => props.setCalOpen(!props.calOpen)}
                              />
                            </Input.Group>
                            <DatePicker
                              className="formControl dateHide"
                              format={DateFormat}
                              open={props.calOpen}
                              style={{ visibility: 'hidden', width: 0 }}
                              onChange={(e) =>
                                props.handleDateformate(
                                  e,
                                  'h_bi_ins_date',
                                  'date'
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} md={6} xl={6}>
                        <div className="formGroup">
                          <Form.Item
                            label="Inspector CP #"
                            name="inspection_cp"
                          >
                            <Input
                              placeholder="Inspector CP"
                              className="formControl"
                              onChange={props.handleChange('inspection_cp')}
                              defaultValue={props.values.inspection_cp}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="CollapseSection">
                    <Collapse
                      defaultActiveKey={
                        props.values.stepOneTogg
                          ? props.values.stepOneTogg
                          : ['1', '2', '3', '4', '5', '6', '7']
                      }
                      onChange={props.handleChange('stepOneTogg')}
                      className="reportCollapse"
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <MinusOutlined className="custom-icon" />
                        ) : (
                          <PlusOutlined className="custom-icon" />
                        )
                      }
                      ghost
                    >
                      <Panel header="Commissioning Party Information" key="1">
                        <Row gutter={16}>
                          {props.values?.client_type ===
                            'Flooring Manufacturer' ? (
                            ''
                          ) : (
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Select Client"
                                  name="client_select_drop"
                                >
                                  <Select
                                    showSearch
                                    defaultValue="Select Client"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                      optionA.children
                                        .toLowerCase()
                                        .localeCompare(
                                          optionB.children.toLowerCase()
                                        )
                                    }
                                    onChange={getClientbyId}
                                    open={isOpenClient}
                                    onDropdownVisibleChange={(visible) =>
                                      setIsOpenClient(visible)
                                    }
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: '0',
                                          }}
                                        />
                                        <Space className="renderBtn">
                                          <Button
                                            type="text"
                                            icon={<PlusOutlined />}
                                            onClick={() => addClient()}
                                            block
                                          >
                                            Add New Client
                                          </Button>
                                        </Space>
                                      </>
                                    )}
                                  >
                                    {props.clientDropdown ? (
                                      props.clientDropdown.map((option, id) => {
                                        return (
                                          <Option value={option.id} key={id}>
                                            {option.name}
                                          </Option>
                                        );
                                      })
                                    ) : (
                                      <Option value="No data">
                                        <Empty />
                                      </Option>
                                    )}
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                          )}
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Commissioning Party"
                                name="comm_party_drop"
                              >
                                <Select
                                  defaultValue={
                                    props.values.comm_party_drop
                                      ? props.values.comm_party_drop
                                      : 'Commissioning Party'
                                  }
                                  onChange={props.handleChange(
                                    'comm_party_drop'
                                  )}
                                >
                                  <Option value="Manufacturer">
                                    Manufacturer
                                  </Option>
                                  <Option value="Dealer">Dealer</Option>
                                  <Option value="Distributor">
                                    Distributor
                                  </Option>
                                  <Option value="Homeowner">Homeowner</Option>
                                  <Option value="Attorney">Attorney</Option>
                                  <Option value="Builder">Builder</Option>
                                  <Option value="Insurance Company">
                                    Insurance Company
                                  </Option>
                                  <Option value="Installer">Installer</Option>
                                  <Option value="Other">Other</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          {props.isOpen.comm_party_drop_Field === true ||
                            props.values.comm_party_drop === 'Other' ? (
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Other"
                                  name="comm_party_drop_other"
                                >
                                  <Input
                                    placeholder="Other"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      'comm_party_drop_other'
                                    )}
                                    defaultValue={
                                      props.values.comm_party_drop_other
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          ) : null}
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Name" name="commisioning_name">
                                <Input
                                  placeholder="Name"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'commisioning_name'
                                  )}
                                  defaultValue={props.values.commisioning_name}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Street Name/Number"
                                name="commisioning_party_street"
                              // valuePropName={commissioningData?.Home_street}
                              >
                                <Input
                                  placeholder="Street Name/Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'commisioning_party_street'
                                  )}
                                  //value={commissioningData?.Home_street}
                                  defaultValue={
                                    props.values.commisioning_party_street
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="City"
                                name="commisioning_party_city"
                              >
                                <Input
                                  placeholder="City"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'commisioning_party_city'
                                  )}
                                  defaultValue={
                                    props.values.commisioning_party_city
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="State"
                                name="commisioning_party_state"
                              >
                                <Input
                                  placeholder="State"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'commisioning_party_state'
                                  )}
                                  defaultValue={
                                    props.values.commisioning_party_state
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Country"
                                name="commisioning_party_country"
                              >
                                <Input
                                  placeholder="Country"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'commisioning_party_country'
                                  )}
                                  defaultValue={
                                    props.values.commisioning_party_country
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Zip"
                                name="commisioning_party_zip"
                              >
                                <Input
                                  placeholder="Zip"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'commisioning_party_zip'
                                  )}
                                  defaultValue={
                                    props.values.commisioning_party_zip
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Contact Number"
                                name="commisioning_party_contact_number"
                              >
                                <Input
                                  placeholder="Contact Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'commisioning_party_contact_number'
                                  )}
                                  defaultValue={
                                    props.values
                                      .commisioning_party_contact_number
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Email"
                                name="commisioning_party_email"
                              >
                                <Input
                                  placeholder="Email"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'commisioning_party_email'
                                  )}
                                  defaultValue={
                                    props.values.commisioning_party_email
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          {isOpenBtn ? (
                            <Col
                              xs={24}
                              md={6}
                              xl={6}
                              className="d-flex justify-content-start align-items-center"
                            >
                              <div className="formGroup">
                                <Button
                                  className="btnSecondary me-2"
                                  onClick={props.addClient}
                                >
                                  Add Client
                                </Button>
                                <Button
                                  className="btnSecondary me-2"
                                  onClick={() => hideClient()}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Col>
                          ) : (
                            ''
                          )}
                        </Row>
                      </Panel>
                      <Panel header="Homeowner/Building Information" key="2">
                        <Row gutter={16}>
                          <div className="m-2">
                            <Form.Item name="commishomeowner">
                              <Checkbox

                                onChange={getHomeOwnerData}
                                defaultChecked={props.values.commishomeowner}
                              >
                                Check this box if Homeowner/Building is
                                Commissioning Party
                              </Checkbox>
                            </Form.Item>
                          </div>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Name"
                                name="homeowner_Name"
                                valuePropName={homeOwnerData?.commisioning_name}
                              >
                                <Input
                                  placeholder="Name"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'homeowner_Name'
                                  )}
                                  value={homeOwnerData?.commisioning_name}
                                  defaultValue={props.values.homeowner_Name}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Street Name/Number"
                                name="homeowner_street"
                                valuePropName={
                                  homeOwnerData?.commisioning_party_street
                                }
                              >
                                <Input
                                  placeholder="Street Name/Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'homeowner_street'
                                  )}
                                  value={
                                    homeOwnerData?.commisioning_party_street
                                  }
                                  defaultValue={props.values.homeowner_street}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="City"
                                name="homeowner_city"
                                valuePropName={
                                  homeOwnerData?.commisioning_party_city
                                }
                              >
                                <Input
                                  placeholder="City"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'homeowner_city'
                                  )}
                                  value={homeOwnerData?.commisioning_party_city}
                                  defaultValue={props.values.homeowner_city}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="State"
                                name="homeowner_state"
                                valuePropName={
                                  homeOwnerData?.commisioning_party_state
                                }
                              >
                                <Input
                                  placeholder="State"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'homeowner_state'
                                  )}
                                  value={
                                    homeOwnerData?.commisioning_party_state
                                  }
                                  defaultValue={props.values.homeowner_state}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Country"
                                name="homeowne_country"
                                valuePropName={
                                  homeOwnerData?.commisioning_party_country
                                }
                              >
                                <Input
                                  placeholder="Country"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'homeowne_country'
                                  )}
                                  defaultValue={props.values.homeowne_country}
                                  value={
                                    homeOwnerData?.commisioning_party_country
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Zip"
                                name="homeowner_zip"
                                valuePropName={
                                  homeOwnerData?.commisioning_party_zip
                                }
                              >
                                <Input
                                  placeholder="Zip"
                                  className="formControl"
                                  onChange={props.handleChange('homeowner_zip')}
                                  defaultValue={props.values.homeowner_zip}
                                  value={homeOwnerData?.commisioning_party_zip}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Contact Number"
                                name="homeowner_contact_number"
                                valuePropName={
                                  homeOwnerData?.commisioning_party_contact_number
                                }
                              >
                                <Input
                                  placeholder="Contact Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'homeowner_contact_number'
                                  )}
                                  value={
                                    homeOwnerData?.commisioning_party_contact_number
                                  }
                                  defaultValue={
                                    props.values.homeowner_contact_number
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Email"
                                name="homeowner_email"
                                valuePropName={
                                  homeOwnerData?.commisioning_party_email
                                }
                              >
                                <Input
                                  placeholder="Email"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'homeowner_email'
                                  )}
                                  value={
                                    homeOwnerData?.commisioning_party_email
                                  }
                                  defaultValue={props.values.homeowner_email}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Panel>
                      <Panel header="Flooring Contractor Information" key="3">
                        <Row gutter={16}>
                          <div className="m-2">
                            <Form.Item name="flooringContractorData">
                              <Checkbox
                                onChange={getFlooringContractorData}
                                defaultChecked={
                                  props.values.flooringContractorData
                                }
                              >
                                Check this box if Flooring Contractor is
                                Commissioning Party
                              </Checkbox>
                            </Form.Item>
                          </div>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Name"
                                name="flooring_contractor_Name"
                                valuePropName={
                                  flooringcontractorData?.commisioning_name
                                }
                              >
                                <Input
                                  placeholder="Name"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'flooring_contractor_Name'
                                  )}
                                  value={
                                    flooringcontractorData?.commisioning_name
                                  }
                                  defaultValue={
                                    props.values.flooring_contractor_Name
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Street Name/Number "
                                name="flooring_contractor_street"
                                valuePropName={
                                  flooringcontractorData?.commisioning_party_street
                                }
                              >
                                <Input
                                  placeholder="Street Name/Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'flooring_contractor_street'
                                  )}
                                  value={
                                    flooringcontractorData?.commisioning_party_street
                                  }
                                  defaultValue={
                                    props.values.flooring_contractor_street
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="City"
                                valuePropName={
                                  flooringcontractorData?.commisioning_party_city
                                }
                                name="flooring_contractor_city"
                              >
                                <Input
                                  placeholder="City"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'flooring_contractor_city'
                                  )}
                                  value={
                                    flooringcontractorData?.commisioning_party_city
                                  }
                                  defaultValue={
                                    props.values.flooring_contractor_city
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="State"
                                valuePropName={
                                  flooringcontractorData?.commisioning_party_state
                                }
                                name="flooring_contractor_state"
                              >
                                <Input
                                  value={
                                    flooringcontractorData?.commisioning_party_state
                                  }
                                  placeholder="State"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'flooring_contractor_state'
                                  )}
                                  defaultValue={
                                    props.values.flooring_contractor_state
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Country"
                                name="flooring_contractor_country"
                                valuePropName={
                                  flooringcontractorData?.commisioning_party_country
                                }
                              >
                                <Input
                                  placeholder="Country"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'flooring_contractor_country'
                                  )}
                                  defaultValue={
                                    props.values.flooring_contractor_country
                                  }
                                  value={
                                    flooringcontractorData?.commisioning_party_country
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Zip"
                                valuePropName={
                                  flooringcontractorData?.commisioning_party_zip
                                }
                                name="flooring_contractor_zip"
                              >
                                <Input
                                  placeholder="Zip"
                                  value={
                                    flooringcontractorData?.commisioning_party_zip
                                  }
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'flooring_contractor_zip'
                                  )}
                                  defaultValue={
                                    props.values.flooring_contractor_zip
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Contact Number"
                                valuePropName={
                                  flooringcontractorData?.commisioning_party_contact_number
                                }
                                name="flooring_contractor_contact_number"
                              >
                                <Input
                                  placeholder="Contact Number"
                                  className="formControl"
                                  value={
                                    flooringcontractorData?.commisioning_party_contact_number
                                  }
                                  onChange={props.handleChange(
                                    'flooring_contractor_contact_number'
                                  )}
                                  defaultValue={
                                    props.values
                                      .flooring_contractor_contact_number
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Email"
                                valuePropName={
                                  flooringcontractorData?.commisioning_party_email
                                }
                                name="flooring_contractor_email"
                              >
                                <Input
                                  value={
                                    flooringcontractorData?.commisioning_party_email
                                  }
                                  placeholder="Email"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'flooring_contractor_email'
                                  )}
                                  defaultValue={
                                    props.values.flooring_contractor_email
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Panel>
                      <Panel header="Manufacturer Information" key="4">
                        <Row gutter={16}>
                          <div className="m-2">
                            <Form.Item name="manufacturerData">
                              <Checkbox
                                onChange={getManufacturerData}
                                defaultChecked={props.values.manufacturerData}
                              >
                                Check this box if Manufacturer is Commissioning
                                Party
                              </Checkbox>
                            </Form.Item>
                          </div>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Name"
                                name="manufacturer_name"
                                valuePropName={
                                  manufacturerData?.commisioning_name
                                }
                              >
                                <Input
                                  value={manufacturerData?.commisioning_name}
                                  placeholder="Name"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_name'
                                  )}
                                  defaultValue={props.values.manufacturer_name}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Street Name/Number "
                                name="manufacturer_street"
                                valuePropName={
                                  manufacturerData?.commisioning_party_street
                                }
                              >
                                <Input
                                  placeholder="Street Name/Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_street'
                                  )}
                                  value={
                                    manufacturerData?.commisioning_party_street
                                  }
                                  defaultValue={
                                    props.values.manufacturer_street
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="City"
                                valuePropName={
                                  manufacturerData?.commisioning_party_city
                                }
                                name="manufacturer_city"
                              >
                                <Input
                                  value={
                                    manufacturerData?.commisioning_party_city
                                  }
                                  placeholder="City"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_city'
                                  )}
                                  defaultValue={props.values.manufacturer_city}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="State"
                                name="manufacturer_state"
                                valuePropName={
                                  manufacturerData?.commisioning_party_state
                                }
                              >
                                <Input
                                  placeholder="State"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_state'
                                  )}
                                  value={
                                    manufacturerData?.commisioning_party_state
                                  }
                                  defaultValue={props.values.manufacturer_state}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Country"
                                name="manufacturer_country"
                                valuePropName={
                                  manufacturerData?.commisioning_party_country
                                }
                              >
                                <Input
                                  placeholder="Country"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_country'
                                  )}
                                  defaultValue={
                                    props.values.manufacturer_country
                                  }
                                  value={
                                    manufacturerData?.commisioning_party_country
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Zip"
                                valuePropName={
                                  manufacturerData?.commisioning_party_zip
                                }
                                name="manufacturer_zip"
                              >
                                <Input
                                  placeholder="Zip"
                                  value={
                                    manufacturerData?.commisioning_party_zip
                                  }
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_zip'
                                  )}
                                  defaultValue={props.values.manufacturer_zip}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Main Contact Info"
                                name="manufacturer_contact_info"
                              >
                                <Input
                                  placeholder="Main Contact Info"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_contact_info'
                                  )}
                                  defaultValue={
                                    props.values.manufacturer_contact_info
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Title"
                                name="manufacturer_title"
                              >
                                <Input
                                  placeholder="Title"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_title'
                                  )}
                                  defaultValue={props.values.manufacturer_title}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Contact Number"
                                name="manufacturer_contact_number"
                                valuePropName={
                                  manufacturerData?.commisioning_party_contact_number
                                }
                              >
                                <Input
                                  value={
                                    manufacturerData?.commisioning_party_contact_number
                                  }
                                  placeholder="Contact Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_contact_number'
                                  )}
                                  defaultValue={
                                    props.values.manufacturer_contact_number
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Email"
                                valuePropName={
                                  manufacturerData?.commisioning_party_email
                                }
                                name="manufacturer_email"
                              >
                                <Input
                                  value={
                                    manufacturerData?.commisioning_party_email
                                  }
                                  placeholder="Email"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'manufacturer_email'
                                  )}
                                  defaultValue={props.values.manufacturer_email}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Panel>
                      <Panel header="Retailer Information" key="5">
                        <Row gutter={16}>
                          <div className="m-2">
                            <Form.Item name="reatailerData">
                              <Checkbox

                                onChange={getReatailerData}
                                defaultChecked={props.values.reatailerData}
                              >
                                Check this box if Retailer is Commissioning
                                Party
                              </Checkbox>
                            </Form.Item>
                          </div>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Name"
                                name="retailer_name"
                                valuePropName={reatailerData?.commisioning_name}
                              >
                                <Input
                                  placeholder="Name"
                                  className="formControl"
                                  onChange={props.handleChange('retailer_name')}
                                  defaultValue={props.values.retailer_name}
                                  value={reatailerData?.commisioning_name}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Street Name/Number "
                                name="retailer_street"
                                valuePropName={
                                  reatailerData?.commisioning_party_street
                                }
                              >
                                <Input
                                  placeholder="Street Name/Number"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'retailer_street'
                                  )}
                                  value={
                                    reatailerData?.commisioning_party_street
                                  }
                                  defaultValue={props.values.retailer_street}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="City"
                                valuePropName={
                                  reatailerData?.commisioning_party_city
                                }
                                name="retailer_city"
                              >
                                <Input
                                  placeholder="City"
                                  value={reatailerData?.commisioning_party_city}
                                  className="formControl"
                                  onChange={props.handleChange('retailer_city')}
                                  defaultValue={props.values.retailer_city}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="State"
                                valuePropName={
                                  reatailerData?.commisioning_party_state
                                }
                                name="retailer_state"
                              >
                                <Input
                                  placeholder="State"
                                  value={
                                    reatailerData?.commisioning_party_state
                                  }
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'retailer_state'
                                  )}
                                  defaultValue={props.values.retailer_state}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Country"
                                name="retailer_country"
                                valuePropName={
                                  reatailerData?.commisioning_party_country
                                }
                              >
                                <Input
                                  placeholder="Country"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'retailer_country'
                                  )}
                                  defaultValue={props.values.retailer_country}
                                  value={
                                    reatailerData?.commisioning_party_country
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Zip"
                                valuePropName={
                                  reatailerData?.commisioning_party_zip
                                }
                                name="retailer_zip"
                              >
                                <Input
                                  placeholder="Zip"
                                  className="formControl"
                                  value={reatailerData?.commisioning_party_zip}
                                  onChange={props.handleChange('retailer_zip')}
                                  defaultValue={props.values.retailer_zip}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Main Contact Info"
                                name="retailer_contact_info"
                              >
                                <Input
                                  placeholder="Main Contact Info"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'retailer_contact_info'
                                  )}
                                  defaultValue={
                                    props.values.retailer_contact_info
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item label="Title" name="retailer_title">
                                <Input
                                  placeholder="Title"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'retailer_title'
                                  )}
                                  defaultValue={props.values.retailer_title}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Contact Number"
                                valuePropName={
                                  reatailerData?.commisioning_party_contact_number
                                }
                                name="retailer_contact_number"
                              >
                                <Input
                                  placeholder="Contact Number"
                                  className="formControl"
                                  value={
                                    reatailerData?.commisioning_party_contact_number
                                  }
                                  onChange={props.handleChange(
                                    'retailer_contact_number'
                                  )}
                                  defaultValue={
                                    props.values.retailer_contact_number
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Email"
                                valuePropName={
                                  reatailerData?.commisioning_party_email
                                }
                                name="retailer_email"
                              >
                                <Input
                                  placeholder="Email"
                                  value={
                                    reatailerData?.commisioning_party_email
                                  }
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'retailer_email'
                                  )}
                                  defaultValue={props.values.retailer_email}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Panel>
                      <Panel header="Jobsite Information" key="6">
                        <Row gutter={16}>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Jobsite Visit Date"
                                name="jobsite_date"
                              >
                                <Input.Group compact className="mt-0">
                                  <Input
                                    placeholder="Jobsite Visit Date"
                                    className="formControl"
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        'jobsite_date',
                                        'text'
                                      )
                                    }
                                    value={props.values.jobsite_date}
                                    onFocus={() =>
                                      props.setCalOpen1(!props.calOpen1)
                                    }
                                    onKeyUp={() => props.setCalOpen1(false)}
                                    onBlur={() => props.setCalOpen1(false)}
                                  />

                                  <Button
                                    className="btnAddOn"
                                    icon={<CalendarOutlined />}
                                    onClick={() =>
                                      props.setCalOpen1(!props.calOpen1)
                                    }
                                  />
                                </Input.Group>
                                <DatePicker
                                  className="formControl dateHide"
                                  format={DateFormat}
                                  open={props.calOpen1}
                                  style={{ visibility: 'hidden', width: 0 }}
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      'jobsite_date',
                                      'date'
                                    )
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup custom-form-group">
                              <Form.Item label="Time" name="jobsite_time">
                                <Input
                                  className="formGroup customPadding"
                                  addonAfter={
                                    <Cascader
                                      placeholder="AM"
                                      onChange={props.handleChange('time_type')}
                                      options={timeampm}
                                      defaultValue={props.values.time_type}
                                      className="custom-cascadar"
                                    />
                                  }
                                  placeholder="Time"
                                  onChange={props.handleChange('jobsite_time')}
                                  defaultValue={props.values.jobsite_time}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Property Type"
                                name="property_dropdown"
                              >
                                <Select
                                  defaultValue={
                                    props.values.property_dropdown
                                      ? props.values.property_dropdown
                                      : 'Select Property Type'
                                  }
                                  onChange={props.handleChange(
                                    'property_dropdown'
                                  )}
                                >
                                  <Option value="Residential">
                                    Residential
                                  </Option>
                                  <Option value="Commercial">Commercial</Option>
                                  <Option value="Rental Unit">
                                    Rental Unit
                                  </Option>
                                  <Option value="Not Applicable">
                                    Not Applicable
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Building Type"
                                name="building_type"
                              >
                                <Select
                                  defaultValue={
                                    props.values.building_type
                                      ? props.values.building_type
                                      : 'Select Building Type'
                                  }
                                  onChange={props.handleChange('building_type')}
                                >
                                  <Option value="Single Story">
                                    Single Story
                                  </Option>
                                  <Option value="Multi-Level">
                                    Multi-Level
                                  </Option>
                                  <Option value="Other">Other</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          {props.isOpen.buildingOther === true ||
                            props.values.building_type === 'Other' ? (
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Other"
                                  name="build_type_other"
                                >
                                  <Input
                                    placeholder="Other"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      'build_type_other'
                                    )}
                                    defaultValue={props.values.build_type_other}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          ) : null}
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Building Style"
                                name="building_style"
                              >
                                <Select
                                  defaultValue={
                                    props.values.building_style
                                      ? props.values.building_style
                                      : 'Select Building Style'
                                  }
                                  onChange={props.handleChange(
                                    'building_style'
                                  )}
                                >
                                  <Option value="Single Family">
                                    Single Family
                                  </Option>
                                  <Option value="Apartment Complex">
                                    Apartment Complex
                                  </Option>
                                  <Option value="Duplex">Duplex</Option>
                                  <Option value="Remodel">Remodel</Option>
                                  <Option value="Condominium">
                                    Condominium
                                  </Option>
                                  <Option value="Church">Church</Option>
                                  <Option value="Common Areas">
                                    Common Areas
                                  </Option>
                                  <Option value="Dental">Dental</Option>
                                  <Option value="Exterior">Exterior</Option>
                                  <Option value="Educational Facility">
                                    Educational Facility
                                  </Option>
                                  <Option value="Golf Course">
                                    Golf Course
                                  </Option>
                                  <Option value="Group Home">Group Home</Option>
                                  <Option value="Hospital">Hospital</Option>
                                  <Option value="Hospitality">
                                    Hospitality
                                  </Option>
                                  <Option value="Manufactured Home">
                                    Manufactured Home
                                  </Option>
                                  <Option value="Medical">Medical </Option>
                                  <Option value="Offices">Offices</Option>
                                  <Option value="Office Building">
                                    Office Building
                                  </Option>
                                  <Option value="Retail Store">
                                    Retail Store
                                  </Option>
                                  <Option value="Veterinary">Veterinary</Option>
                                  <Option value="Watercraft">Watercraft</Option>
                                  <Option value="Other">Other</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          {props.isOpen.buildingStyleOther === true ||
                            props.values.building_style === 'Other' ? (
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Other"
                                  name="other_building_style"
                                >
                                  <Input
                                    placeholder="Other"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      'other_building_style'
                                    )}
                                    defaultValue={
                                      props.values.other_building_style
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          ) : null}
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Age of Building"
                                name="jobsite__age_building"
                              >
                                <Input
                                  placeholder="Age of Building"
                                  className="formControl"
                                  onChange={props.handleChange(
                                    'jobsite__age_building'
                                  )}
                                  defaultValue={
                                    props.values.jobsite__age_building
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="New Construction?"
                                name="h_bui_cons"
                              >
                                {console.warn("tripleToggle")}
                                <TripleToggle
                                  value={props.values.h_bui_cons}
                                  onToggle={props.handleChange('h_bui_cons')}
                                />
                              </Form.Item>
                            </div>
                            {props.values.h_bui_cons === 'Yes' ||
                              props.isOpen.constructionField === true ? (
                              <div>
                                <Row gutter={16}>
                                  <Col xs={24} md={24} xl={24}>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Closing Date"
                                        name="closing_date"
                                      >
                                        <Input.Group compact className="mt-0">
                                          <Input
                                            placeholder="Closing Date"
                                            className="formControl"
                                            onChange={(e) =>
                                              props.handleDateformate(
                                                e,
                                                'closing_date',
                                                'text'
                                              )
                                            }
                                            value={props.values.closing_date}
                                            onFocus={() =>
                                              props.setCalOpen3(!props.calOpen3)
                                            }
                                            onKeyUp={() =>
                                              props.setCalOpen3(false)
                                            }
                                            onBlur={() =>
                                              props.setCalOpen3(false)
                                            }
                                          />

                                          <Button
                                            className="btnAddOn"
                                            icon={<CalendarOutlined />}
                                            onClick={() =>
                                              props.setCalOpen3(!props.calOpen3)
                                            }
                                          />
                                        </Input.Group>
                                        <DatePicker
                                          className="formControl dateHide"
                                          format={DateFormat}
                                          open={props.calOpen3}
                                          style={{
                                            visibility: 'hidden',
                                            width: 0,
                                          }}
                                          onChange={(e) =>
                                            props.handleDateformate(
                                              e,
                                              'closing_date',
                                              'date'
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Move in Date"
                                        name="moving_date"
                                      >
                                        <Input.Group compact className="mt-0">
                                          <Input
                                            placeholder="Move in Date"
                                            className="formControl"
                                            onChange={(e) =>
                                              props.handleDateformate(
                                                e,
                                                'moving_date',
                                                'text'
                                              )
                                            }
                                            value={props.values.moving_date}
                                            onFocus={() =>
                                              props.setCalOpen4(!props.calOpen4)
                                            }
                                            onKeyUp={() =>
                                              props.setCalOpen4(false)
                                            }
                                            onBlur={() =>
                                              props.setCalOpen4(false)
                                            }
                                          />

                                          <Button
                                            className="btnAddOn"
                                            icon={<CalendarOutlined />}
                                            onClick={() =>
                                              props.setCalOpen4(!props.calOpen4)
                                            }
                                          />
                                        </Input.Group>
                                        <DatePicker
                                          className="formControl dateHide"
                                          format={DateFormat}
                                          open={props.calOpen4}
                                          style={{
                                            visibility: 'hidden',
                                            width: 0,
                                          }}
                                          onChange={(e) =>
                                            props.handleDateformate(
                                              e,
                                              'moving_date',
                                              'date'
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="HVAC Operational Date"
                                        name="operational_date"
                                      >
                                        <Input.Group compact className="mt-0">
                                          <Input
                                            placeholder="HVAC Operational Date"
                                            className="formControl"
                                            onChange={(e) =>
                                              props.handleDateformate(
                                                e,
                                                'operational_date',
                                                'text'
                                              )
                                            }
                                            value={
                                              props.values.operational_date
                                            }
                                            onFocus={() =>
                                              props.setCalOpen5(!props.calOpen5)
                                            }
                                            onKeyUp={() =>
                                              props.setCalOpen5(false)
                                            }
                                            onBlur={() =>
                                              props.setCalOpen5(false)
                                            }
                                          />

                                          <Button
                                            className="btnAddOn"
                                            icon={<CalendarOutlined />}
                                            onClick={() =>
                                              props.setCalOpen5(!props.calOpen5)
                                            }
                                          />
                                        </Input.Group>
                                        <DatePicker
                                          className="formControl dateHide"
                                          format={DateFormat}
                                          open={props.calOpen5}
                                          style={{
                                            visibility: 'hidden',
                                            width: 0,
                                          }}
                                          onChange={(e) =>
                                            props.handleDateformate(
                                              e,
                                              'operational_date',
                                              'date'
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="Occupied"
                                name="h_bui_occupied"
                              >
                                {console.warn('TripleToggle')}
                                <TripleToggle
                                  value={props.values.h_bui_occupied}
                                  onToggle={props.handleChange('h_bui_occupied')}
                                />
                              </Form.Item>
                            </div>
                            {props.isOpen.buildField === true ||
                              props.values.h_bui_occupied === 'Yes' ? (
                              <div>
                                <Row>
                                  <Col xs={24} md={24} xl={24}>
                                    <div className="formGroup">
                                      <Form.Item label="Adults" name="adults">
                                        <Input
                                          placeholder="Adults"
                                          className="formControl"
                                          onChange={props.handleChange(
                                            'adults'
                                          )}
                                          defaultValue={props.values.adults}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="formGroup">
                                      <Form.Item
                                        label="Children"
                                        name="childrens"
                                      >
                                        <Input
                                          placeholder="Children"
                                          className="formControl"
                                          onChange={props.handleChange(
                                            'childrens'
                                          )}
                                          defaultValue={props.values.childrens}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="formGroup">
                                      <Form.Item label="Pets" name="pets">
                                        <Input
                                          placeholder="Pets"
                                          className="formControl"
                                          onChange={props.handleChange('pets')}
                                          defaultValue={props.values.pets}
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col xs={24} md={24}>
                            <div className="m-2 formGroup"><label>Statement of Concern</label></div>
                          </Col>
                          <Col xs={24} md={24} xl={24}>
                            <div className="formGroup">
                              <div className="text-editor-wrapper">
                                <SunEditor
                                  setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                  setOptions={SunEditorOpts}
                                  onImageUploadBefore={onImageUploadBefore}
                                  setContents={editorStatConcern}
                                  onChange={onStatConcernChange}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Form>
      )}
    </>
  );
};

export default FirstStep;
