import React, { useEffect, useState } from 'react';
import {  useSelector } from "react-redux";
import { Table, Button, Tooltip, Select, Input, Row, Col, Modal } from 'antd';
import { EnvironmentOutlined, ReloadOutlined, SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import ButtonBar from "../../components/ButtonNavigation/index";
import './DataTable.css';

const { Option } = Select;
const { Search } = Input;

const FindNearbyInspectorsButton = ({ onClick }) => (
    <Tooltip title="Find nearby inspectors">
      <Button 
        className='icon-title-table-button'
        icon={<EnvironmentOutlined />}
        type="primary"
        onClick={onClick}
        style={{
          alignItems: 'center',
          backgroundColor: '#0A497B',
          borderColor: '#0A497B',
          marginRight: '10px'
        }}
      >
        Find Inspectors
      </Button>
    </Tooltip>
);

const SendtoFsManagerButton = ({ onClick }) => (
  <Tooltip title="Send to field service manager">
    <Button
      className='icon-title-table-button'
      icon={<SendOutlined />}
      type="primary"
      onClick={onClick}
      style={{
        alignItems: 'center',
        backgroundColor: '#0A497B',
        borderColor: '#0A497B',
        marginRight: '10px'
      }}
    >
      Send to FS manager
    </Button>
  </Tooltip>
);


const FieldServicesClaims = () => {
  const userDetails = useSelector((state) => state.User.userDetails);
  const [currentUser, setCurrentUser] = useState({});
  const [range, setRange] = useState('100 miles'); // Default range
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchClaimNumber, setSearchClaimNumber] = useState('');
  const [searchAddress, setSearchAddress] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  useEffect(() => {
    fetchData();
    setCurrentUser(userDetails);
  }, [userDetails]);

  useEffect(() => {
    filterData(); // eslint-disable-next-line
  }, [searchClaimNumber, searchAddress, data]);

  const handleRangeChange = (value) => {
    setRange(value);
  };

  const handleRedirect = (euAddress, range, claim_number) => {
    const numericRange = range.split(' ')[0]; // Extract numeric part of range
    const address = encodeURIComponent(euAddress);
    const post = encodeURIComponent('inspector_profile');
    const taxInspectorType = encodeURIComponent('');
    const distance = encodeURIComponent(numericRange); // Use the numeric range
    const units = encodeURIComponent('imperial');
    const perPage = encodeURIComponent('100');
    const form = encodeURIComponent('3');
    const action = encodeURIComponent('fs');
    const claimNumber = encodeURIComponent(claim_number);

    const url = `https://www.floordetective.com/locator/?address[0]=${address}&post[0]=${post}&tax[inspector_type][0]=${taxInspectorType}&distance=${distance}&units=${units}&per_page=${perPage}&form=${form}&action=${action}&claim_number=${claimNumber}`;

    window.location.href = url;
  };
  const handleRedirectFS = (userId, claimNumber) => {
    const encodedClaimNumber = encodeURIComponent(claimNumber);
    const baseUrl = 'https://www.floordetective.com/hire-inspector/';
    let clientId, clientTypeId, clientType;
  
    if (currentUser?.user_role === 'um_claim-analyst') {
      clientId = currentUser?.manufacturer_id;
      clientTypeId = currentUser?.user_id;
      clientType = "claim-analyst";
    } else {
      clientId = currentUser?.user_id;
    }
  
    const url = `${baseUrl}?user-id=${userId}&field-service=true&client-id=${clientId}&claim_number=${encodedClaimNumber}` + 
                (clientTypeId ? `&client-type-id=${clientTypeId}&client-type=${clientType}` : '');
  
    window.location.href = url;
  };
  

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://www.floordetective.com/wp-json/fd_api/v1/getMohawkClaims');
      setData(response.data.value);
      setFilteredData(response.data.value);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsErrorModalVisible(true);
    }
  };

  const filterData = () => {
    const filtered = data.filter(item => item.claim_status === '21I')
      .filter(item =>
        (item.claim_number && item.claim_number.toString().toLowerCase().includes(searchClaimNumber.toLowerCase())) &&
        (item.eu_address && item.eu_address.toString().toLowerCase().includes(searchAddress.toLowerCase()))
      );
    setFilteredData(filtered);
  };

  const showModal = (claim) => {
    setSelectedClaim(claim);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setSelectedClaim(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedClaim(null);
  };

  const handleRetry = () => {
    setIsErrorModalVisible(false);
    fetchData();
  };

  const handleReload = () => {
    window.location.reload();
  };

  const columns = [
    {
      title: 'Claim Number',
      dataIndex: 'claim_number',
      key: 'claim_number',
      render: (text, record) => (
        <button
          onClick={() => showModal(record)}
          style={{
            background: 'none',
            border: 'none',
            color: '#0A497B', // Set the default color to #0A497B
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 'bold', // Make the text bold
            padding: 0
          }}
          onMouseEnter={(e) => {
            e.target.style.textDecoration = 'none'; // Remove underline on hover
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = 'underline'; // Revert underline on mouse leave
          }}
        >
          {text}
        </button>
      )
    },
    {
      title: 'Claim Analyst',
      dataIndex: 'comm_party_first_last_name',
      key: 'comm_party_first_last_name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Inspection Site',
      dataIndex: 'eu_address',
      key: 'eu_address',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <div className="actionBtn" style={{ display: 'flex', alignItems: 'center' }}>
          <Select defaultValue="100 miles" onChange={handleRangeChange} style={{ width: 120, marginRight: 10 }}>
            <Option value="25 miles">25 miles</Option>
            <Option value="50 miles">50 miles</Option>
            <Option value="100 miles">100 miles</Option>
            <Option value="200 miles">200 miles</Option>
            <Option value="500 miles">500 miles</Option>
          </Select>
          <FindNearbyInspectorsButton onClick={() => handleRedirect(record.eu_address, range, record.claim_number)} />
          <SendtoFsManagerButton onClick={() => handleRedirectFS(993532, record.claim_number)} />
        </div>
      ),
    },
    // Add more columns as necessary based on your data
  ];

  return (
    <>
      <div className="buttonSection">
        <ButtonBar />
      </div>
      <Row gutter={16} style={{ marginBottom: 16, marginTop: 16 }}>
        <Col>
          <Search
          className='input-search-border'
            placeholder="Search Claim Number"
            value={searchClaimNumber}
            onChange={(e) => setSearchClaimNumber(e.target.value)}
            onSearch={filterData}
          />
        </Col>
        <Col>
          <Search
          className='input-search-border'
            placeholder="Search Address"
            value={searchAddress}
            onChange={(e) => setSearchAddress(e.target.value)}
            onSearch={filterData}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        rowKey="claim_number"  // Assuming `claim_number` is unique for each row
        className="custom-table"
      />
      {selectedClaim && (
        <Modal
          title="Claim Details"
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          width={800}
          footer={null} // Optional: to remove the default OK/Cancel buttons
        >
          <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', border: '1px solid #e0e0e0' }}>
            <div style={{ padding: '10px', borderBottom: '2px solid #0a497b', marginBottom: '10px' }}>
              <p style={{ color: '#0a497b', fontWeight: 'bold', marginBottom: '8px' }}>Claim Information</p>
              <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                <p><strong>Claim Number:</strong> {selectedClaim.claim_number}</p>
                <p><strong>Claim Status:</strong> {selectedClaim.claim_status}</p>
                <p><strong>Concern Type:</strong> {selectedClaim.concern_type}</p>
              </div>
            </div>

            <div style={{ padding: '10px', borderBottom: '2px solid #0a497b', marginBottom: '10px' }}>
              <p style={{ color: '#0a497b', fontWeight: 'bold', marginBottom: '8px' }}>Analyst Information</p>
              <div>
                <p><strong>Claim Analyst:</strong> {selectedClaim.comm_party_first_last_name}</p>
                <p><strong>Phone Number:</strong> {selectedClaim.comm_party_phone_number}</p>
                <p><strong>Email:</strong> {selectedClaim.comm_party_email}</p>
                <p><strong>Company:</strong> {selectedClaim.comm_party_company_name}</p>
                <p><strong>Company Address:</strong> {`${selectedClaim.comm_party_street_name_number}, ${selectedClaim.comm_party_city}, ${selectedClaim.comm_party_state}, ${selectedClaim.comm_party_zip_code}, ${selectedClaim.comm_party_country}`}</p>
              </div>
            </div>

            <div style={{ padding: '10px', borderBottom: '2px solid #0a497b', marginBottom: '10px' }}>
              <p style={{ color: '#0a497b', fontWeight: 'bold', marginBottom: '8px' }}>Dealer Information</p>
              <div>
                <p><strong>Dealer Name:</strong> {selectedClaim.dealer_name}</p>
                <p><strong>Contact:</strong> {selectedClaim.dealer_contact}</p>
                <p><strong>Phone:</strong> {selectedClaim.dealer_phone}</p>
                <p><strong>Email:</strong> {selectedClaim.dealer_email}</p>
              </div>
            </div>

            <div style={{ padding: '10px', borderBottom: '2px solid #0a497b', marginBottom: '10px' }}>
              <p style={{ color: '#0a497b', fontWeight: 'bold', marginBottom: '8px' }}>End User Information</p>
              <div>
                <p><strong>End User Name:</strong> {selectedClaim.eu_name}</p>
                <p><strong>Address:</strong> {selectedClaim.eu_address}</p>
                <p><strong>Phone:</strong> {selectedClaim.eu_phone2}</p>
              </div>
            </div>

            <div style={{ padding: '10px', borderBottom: '2px solid #0a497b', marginBottom: '10px' }}>
              <p style={{ color: '#0a497b', fontWeight: 'bold', marginBottom: '8px' }}>Product Information</p>
              <div>
                <p><strong>Style Name:</strong> {selectedClaim.style_name}</p>
                <p><strong>Color:</strong> {selectedClaim.color}</p>
                <p><strong>Quantity:</strong> {selectedClaim.quantity}</p>
                <p><strong>Category:</strong> {selectedClaim.category}</p>
              </div>
            </div>

            <div style={{ padding: '10px', borderBottom: '2px solid #0a497b', marginBottom: '10px' }}>
              <p style={{ color: '#0a497b', fontWeight: 'bold', marginBottom: '8px' }}>Installation Information</p>
              <div>
                <p><strong>Installed By:</strong> {selectedClaim.installed_by}</p>
                <p><strong>Installation Date:</strong> {selectedClaim.installation_date}</p>
                <p><strong>Problem Date:</strong> {selectedClaim.problem_date}</p>
              </div>
            </div>

            <div style={{ padding: '10px', borderBottom: '2px solid #0a497b', marginBottom: '10px' }}>
              <p style={{ color: '#0a497b', fontWeight: 'bold', marginBottom: '8px' }}>Additional Comments</p>
              <div>
                <p>{selectedClaim.comments}</p>
              </div>
            </div>
          </div>

        </Modal>
      )}

      {isErrorModalVisible && (
        <Modal
          title="Error"
          visible={isErrorModalVisible}
          onCancel={() => setIsErrorModalVisible(false)}
          footer={[
            <Button key="retry" onClick={handleRetry} icon={<ReloadOutlined />}>
              Retry
            </Button>,
            <Button key="reload" type="primary" onClick={handleReload}>
              Reload Page
            </Button>,
          ]}
        >
          <p>Failed to fetch data. Please try again or reload the page.</p>
        </Modal>
      )}
    </>
  );
};

export default FieldServicesClaims;
