import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
  SecondaryButton,
  DefaultButton,
} from '../Buttons/buttons';
import { MailOutlined } from '@ant-design/icons';
import { logout } from '../../auth/helpers';

import { useSelector } from "react-redux";
import LogoutDropdown from './LogoutDropdown';
import LimitedAccessBadge from "./LimitedAccessBadge";

function TopBar() {
  const [windowDimension, setWindowDimension] = useState(null);
  const userDetails = useSelector((state) => state.User.userDetails);
  const isLimitedAccess = userDetails?.limited_access;
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    setCurrentUser(userDetails);
    setWindowDimension(window.innerWidth);
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const isMobile = windowDimension <= 991;

  let navigate = useNavigate();

  const handleClick = () => {
    navigate('/inspection-reports');
  };

  const handleClickClaim = () => {
    navigate('/claim-analyst-panel');
  };

  const handleClickManuf = () => {
    navigate('/manufacturer-panel');
  };

  const handleIndustry = () => {
    navigate('/industry-standards');
  };


  return (
    <div className="container-xxl">
      <div className="topBar">
        <Row gutter={16}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="d-flex align-items-center"
          >
            <div className="topMailSection d-flex">
              <Link
                to={'mailto:flooringinspections@gmail.com'}
                className="mailLink d-flex align-items-center"
              >
                <MailOutlined className="me-2" /> flooringinspections@gmail.com
              </Link>
              <div className="social-menu-header  d-flex">
                <ul className=" d-flex  px-2">
                  <li><a href="https://twitter.com/floor_detective/" target="blank"><Image preview={false} src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/06/28055457/icons8-twitter-18.png"
                  /></a></li>
                  <li><a href="https://www.instagram.com/floor_detective/" target="blank"><Image preview={false} src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/06/28064837/icons8-instagram-20.png"
                  /></a></li>
                  <li><a href="https://www.linkedin.com/company/floor-detective/" target="blank"><Image preview={false} src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/06/28055509/icons8-linkedin-18.png"
                  /></a></li>
                  <li><a href="https://www.facebook.com/floordetective1"><Image preview={false} src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/06/28055445/icons8-facebook-18.png"
                  /></a></li>
                </ul>
              </div>

            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="d-flex justify-content-end"
          >
            {isMobile ? (
              ""
            ) : (
              <div className="topbarBtnSection">
                <div className="btnGroup">
                  {currentUser?.user_role === 'um_manufacturer' ?
                    (
                      <>
                        <SecondaryButton
                          type="secondary"
                          handleClick={() => handleClickManuf()}
                          text="Claim"
                          className={window.location.pathname === '/manufacturer-panel' ? 'active me-2' : 'me-2'}
                        />
                        <SecondaryButton
                          type="secondary"
                          handleClick={() => handleIndustry()}
                          text="Industry Standards"
                          className={window.location.pathname === '/industry-standards' ? 'active' : ''}
                        />
                        <DefaultButton
                          type="default"
                          handleClick={logout}
                          text="Log Out"
                        />
                      </>
                    ) : ''
                  }
                  {currentUser?.user_role === 'um_claim-analyst' ? (
                    <>
                      <SecondaryButton
                        type="secondary"
                        handleClick={() => handleClickClaim()}
                        text="Claim"
                        className={window.location.pathname === '/claim-analyst-panel' ? 'active me-2' : 'me-2'}
                      />
                      <SecondaryButton
                        type="secondary"
                        handleClick={() => handleIndustry()}
                        text="Industry Standards"
                        className={window.location.pathname === '/industry-standards' ? 'active' : ''}
                      />
                      <DefaultButton
                        type="default"
                        handleClick={logout}
                        text="Log Out"
                      />
                    </>
                  ) : ''}

                  {(currentUser?.user_role === 'um_dealer' || currentUser?.user_role === 'um_fs_manager' || currentUser?.user_role === 'um_fs_inspector') ? (
                    <DefaultButton
                      type="default"
                      handleClick={logout}
                      text="Log Out"
                    />
                  ) : ''
                  }

                  {currentUser?.user_role === 'um_inspector' ? (
                    <>
                      <LimitedAccessBadge isLimitedAccess={isLimitedAccess} />
                      <SecondaryButton
                        type="secondary"
                        handleClick={() => handleClick()}
                        text="Inspection Report"
                        className={window.location.pathname === '/inspection-reports' ? 'active me-2' : 'me-2'}
                      />
                      <SecondaryButton
                        type="secondary"
                        handleClick={() => handleIndustry()}
                        text="Industry Standards"
                        className={window.location.pathname === '/industry-standards' ? 'active' : ''}
                      />
                      <LogoutDropdown userDetails={currentUser} />
                    </>
                  ) : ''
                  }
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TopBar;
