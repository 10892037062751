import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Select, Button, Row, Col } from 'antd';
import PagesHeader from '../../components/PageHeader';
import InspectionRequestData from '../../datatables/inspectionDataTable';
import * as InspectionServices from '../../services/createManualrequest';
import { useDispatch, useSelector } from 'react-redux';
import ButtonBar from '../../components/ButtonNavigation'
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const InspectionRequest = (props) => {
  var dispatch = useDispatch();
  const [loadings, setLoadings] = useState([]);
  const [modalInspection, setModalInspection] = useState(false);
  const [value, setValue] = useState();
  const userDetails = useSelector((state) => state.User.userDetails);
  const isLimitedAccess = userDetails?.limited_access;
  let navigate = useNavigate();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setModalInspection(true);
  };

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const handleSubmit = (e, index) => {
    if (value) {
      enterLoading(0);
      let dropDownval = {
        floor_category: value
      };
      dispatch(InspectionServices.createManualreq(dropDownval)).then((res) => {
        e.preventDefault();
        let navTo = getCategorytitle(value);
        navigate(`/${navTo}`);
        enterLoading(0);
      });
    } else {
      e.preventDefault();
    }
  };

  const getCategorytitle = (value) => {
    if (value === "Quick Report") {
      return "quickReport";
    } else if (value === "Carpet -Basic") {
      return "carpetBasic";
    } else if (value === "Carpet") {
      return "carpetDetailed";
    } else if (value === "Hard Surface -Basic") {
      return "hardwoodBasic";
    } else if (value === "Hardwood") {
      return "hardwoodDetailed";
    } else if (value === "LVT") {
      return "LVT";
    } else if (value === "Tile") {
      return "Tile";
    } else if (value === "Laminate") {
      return "Laminate";
    } else if (value === "NWFA Sand Finish") {
      return "nwfa-sand-finish-inspection-report";
    } else if (value === "NWFA Installation") {
      return "nwfa-installation-inspection-report";
    } else {
      return false;
    }
  };

  const options = [
    {
      id: 1,
      value: "Quick Report",
      label: "Quick Report",
    },
    {
      id: 2,
      value: "Carpet -Basic",
      label: "Carpet -Basic",
    },
    {
      id: 3,
      value: "Carpet",
      label: "Carpet -Detailed",
    },
    {
      id: 4,
      value: "Hard Surface -Basic",
      label: "Hardwood -Basic",
    },
    {
      id: 5,
      value: "Hardwood",
      label: "Hardwood -Detailed",
    },
    {
      id: 6,
      value: "LVT",
      label: "LVT",
    },
    {
      id: 7,
      value: "Tile",
      label: "Tile",
    },
    {
      id: 8,
      value: "Laminate",
      label: "Laminate",
    }
  ];

  const optionsNwfa = [
    {
      id: 1,
      value: "Quick Report",
      label: "Quick Report",
    },
    {
      id: 2,
      value: "Carpet -Basic",
      label: "Carpet -Basic",
    },
    {
      id: 3,
      value: "Carpet",
      label: "Carpet -Detailed",
    },
    {
      id: 4,
      value: "Hard Surface -Basic",
      label: "Hardwood -Basic",
    },
    {
      id: 5,
      value: "Hardwood",
      label: "Hardwood -Detailed",
    },
    {
      id: 6,
      value: "LVT",
      label: "Resilient/LVT/MLF",
    },
    {
      id: 7,
      value: "Tile",
      label: "Tile",
    },
    {
      id: 8,
      value: "Laminate",
      label: "Laminate",
    },
    {
      id: 9,
      value: "NWFA Sand Finish",
      label: "NWFA Sand Finish",
    },
    {
      id: 10,
      value: "NWFA Installation",
      label: "NWFA Installation",
    },
  ];

  return (
    <div className="inspectionWrapper">
      <div className="inspectionHeader">
        <PagesHeader
          title="Inspection Reports"
        />
      </div>
      <div className="buttonSection">
        <div className="container-xxl">
          <Row>
            <Col xs={24} md={18} className="text-end">
              <ButtonBar />
            </Col>
            <Col xs={24} md={6}>
              <Button
                type='success'
                className="btn inspectionAdd"
                onClick={() => handleClick()}
                disabled={isLimitedAccess}
                icon={<PlusOutlined />}
              >
                New Inspection
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="inspectionWrapInner">
        <div className="container-xxl">
          <InspectionRequestData />
        </div>
      </div>

      {/* Inspection Modal */}

      <Modal
        title="Floor Category"
        centered
        open={modalInspection}
        onCancel={() => setModalInspection(false)}
        className="inspectionCategoryModal inspection_catergory_popup"
        footer={[
          <Button
            key="submit"
            htmlType="submit"
            className="submitBtn"
            onClick={handleSubmit}
            loading={loadings[0]}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            {userDetails?.nwfa_certified === '1' ? (
              <Select
                placeholder="Select Floor Category"
                onChange={handleChange}
                value={value}
              >
                {optionsNwfa.map((option) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
              </Select>
            ) : (
              <Select
                placeholder="Select Floor Category"
                onChange={handleChange}
                value={value}
              >
                {options.map((option) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
              </Select>
            )}

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InspectionRequest;
