import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  Form,
  Switch,
  Select,
  Collapse,
  Button,
  Tooltip,
  Modal,
  message,
  Cascader,
  Upload,
  Image,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
  CopyOutlined,
  FormOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import SkeletonIndustryStandard from "../SkeletonIndustryStandard";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
import axios from "axios";
import { MEDIA_UPLOAD_URL } from "../../../Helper";
import Upload1 from "../uploadMultistep";
import { useDispatch } from "react-redux";
import * as FooterLogoService from "../../../services/FooterLogo";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import * as getSuggestionService from "../../../services/getSuggestion";

const options = [
  {
    value: "°F",
    label: "°F",
  },
  {
    value: "°C",
    label: "°C",
  },
];

const FourthStep = (props) => {
  console.warn("FourthStep", props);
  var dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterStandards, setFilterStandards] = useState([]);
  const [modalIndustry, setModalIndustry] = useState(false);
  const [industryContent, setindustryContent] = useState("");
  const [sidebarState, setsidebarState] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [footerLogo, setFooterLogo] = useState([]);
  const [selectedlogs, setselectedLogos] = useState([]);
  const [sign, setSign] = useState([]);
  const [moisTres, setmoisTres] = useState("");
  const [comReadings, setcomReadings] = useState("");
  const [othMoiTestConducted, setothMoiTestConducted] = useState("");
  const [lOfTets, setlOfTets] = useState("");
  const [lofTetsAdheManu, setlofTetsAdheManu] = useState("");
  const [locatiOfTests, setlocatiOfTests] = useState("");
  const [comReading, setcomReading] = useState("");
  const [editorState5, setEditorState5] = useState("");
  const [editorState6, setEditorState6] = useState("");
  const [editorState7, setEditorState7] = useState("");
  const [editorState8, setEditorState8] = useState("");
  const [editorState9, setEditorState9] = useState("");

  const moisTresChange = (editorData) => {
    setmoisTres(editorData);
    props.values.mois_t_res = editorData;
    props.handleEditor(editorData, "mois_t_res");
  };
  const comReadingsChange = (editorData) => {
    setcomReadings(editorData);
    props.values.com_readings = editorData;
    props.handleEditor(editorData, "com_readings");
  };
  const othMoiTestConductedChange = (editorData) => {
    setothMoiTestConducted(editorData);
    props.values.oth_moi_test_conducted = editorData;
    props.handleEditor(editorData, "oth_moi_test_conducted");
  };
  const lOfTetsChange = (editorData) => {
    setlOfTets(editorData);
    props.values.l_of_tets = editorData;
    props.handleEditor(editorData, "l_of_tets");
  };
  const lofTetsAdheManuChange = (editorData) => {
    setlofTetsAdheManu(editorData);
    props.values.l_of_tets_adhe_manu = editorData;
    props.handleEditor(editorData, "l_of_tets_adhe_manu");
  };
  const locatiOfTestsChange = (editorData) => {
    setlocatiOfTests(editorData);
    props.values.locati_of_tests = editorData;
    props.handleEditor(editorData, "locati_of_tests");
  };
  const comReadingChange = (editorData) => {
    setcomReading(editorData);
    props.values.com_reading = editorData;
    props.handleEditor(editorData, "com_reading");
  };
  const onEditorStateChange5 = (editorData) => {
    setEditorState5(editorData);
    props.values.apply_industry_stndr = editorData;
    props.handleEditor(editorData, "apply_industry_stndr");
  };
  const onEditorStateChange6 = (editorData) => {
    setEditorState6(editorData);
    props.values.add_testing_information = editorData;
    props.handleEditor(editorData, "add_testing_information");
  };

  const onEditorStateChange7 = (editorData) => {
    setEditorState7(editorData);
    props.values.conclusion_cause = editorData;
    props.handleEditor(editorData, "conclusion_cause");
  };
  const onEditorStateChange8 = (editorData) => {
    setEditorState8(editorData);
    props.values.footer_text = editorData;
    props.handleEditor(editorData, "footer_text");
  };
  const onEditorStateChange9 = (editorData) => {
    setEditorState9(editorData);
    props.values.private_notes = editorData;
    props.handleEditor(editorData, "private_notes");
  };

  console.warn("check", setLoading, setsidebarState);
  const onImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData()
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    formData.append('file', files[0])
    axios.post(MEDIA_UPLOAD_URL, formData, config)
      .then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.data.source_url,
              "name": files[0].name,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response)
      })
  }
  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getSuggestion = (label, category) => {
    setLoading(true);
    let suggestiondata = {
      label: label,
      category: category,
    };
    dispatch(getSuggestionService.getindStandard(suggestiondata))
      .then((res) => {
        setsidebarState(res);
        setLoading(false);
      })
      .catch((err) => {
        // Extract and display a meaningful error message
        let errorMessage;

        if (err.response) {
          // The request was made and the server responded with a status code outside of the 2xx range
          errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
        } else if (err.request) {
          // The request was made but no response was received
          errorMessage = "No response received from the server. Please check your network connection.";
        } else {
          // Something else happened while setting up the request
          errorMessage = `An unexpected error occurred: ${err.message}`;
        }

        // Display the error message in an alert or use a UI notification component
        alert(errorMessage);
      });
  };

  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: "150px",
    imageHeight: "150px",
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule", "lineHeight"],
      ["outdent", "indent"], ["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        props.values.signurl = url;
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  console.log(sign);
  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess("Ok");
      setSign(res);
      console.log("server res: ", res);
      props.values.signature = res?.data?.id;
      console.log("props.values.signature", props.values.signature);
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err });
      console.warn(error);
    }
  };

  const takefileList = (list) => {
    props.values.attachments = list;
  };
  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(FooterLogoService.getLogos())
      .then((res) => {
        setFooterLogo(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    setSign(props.values?.signId);
    setselectedLogos(props.values?.footerLogo);
    setImageUrl(props.values?.signurl);
    props.values.mois_t_res ? setmoisTres(props.values.mois_t_res) : setmoisTres("");
    props.values.com_readings ? setcomReadings(props.values.com_readings) : setcomReadings("");
    props.values.oth_moi_test_conducted ? setothMoiTestConducted(props.values.oth_moi_test_conducted) : setothMoiTestConducted("");
    props.values.l_of_tets ? setlOfTets(props.values.l_of_tets) : setlOfTets("");
    props.values.l_of_tets_adhe_manu ? setlofTetsAdheManu(props.values.l_of_tets_adhe_manu) : setlofTetsAdheManu("");
    props.values.locati_of_tests ? setlocatiOfTests(props.values.locati_of_tests) : setlocatiOfTests("");
    props.values.com_reading ? setcomReading(props.values.com_reading) : setcomReading("");
    props.values.apply_industry_stndr ? setEditorState5(props.values.apply_industry_stndr) : setEditorState5("");
    props.values.add_testing_information ? setEditorState6(props.values.add_testing_information) : setEditorState6("");
    props.values.conclusion_cause ? setEditorState7(props.values.conclusion_cause) : setEditorState7("");
    props.values.footer_text ? setEditorState8(props.values.footer_text) : setEditorState8("");
    props.values.private_notes ? setEditorState9(props.values.private_notes) : setEditorState9("");
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.footerLogo]);

  useEffect(() => {
    console.info("Useffect Called");

    if (sidebarState) {
      const filteredData = sidebarState.filter((entry) =>
        entry?.title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilterStandards(filteredData);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleClick = (item1) => {
    setModalIndustry(true);
    sidebarState.filter((item) =>
      item.id === item1 ? setindustryContent(item) : ""
    );
  };

  const sendTextToEditor = (text, label) => {
    if (label === "applicable-field-tests") {
      setEditorState6(editorState6 + text);
      onEditorStateChange6(editorState6 + text);
    } else if (label === "industry_standards") {
      setEditorState5(editorState5 + text);
      onEditorStateChange5(editorState5 + text);
    } else if (label === "conclusion") {
      setEditorState7(editorState7 + text);
      onEditorStateChange7(editorState7 + text);
    }
    else if (label === "footer-text") {
      setEditorState8(editorState8 + text);
      onEditorStateChange8(editorState8 + text);
    }
  }
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const { Option } = Select;
  return (
    <>
      <Form name="report" autoComplete="off" layout="vertical" form={form}>
        <Card className="reportCard">
          <div className="reportCardBody">
            <div className="formSection">
              <div className="inspectionSection">
                <div className="CollapseSection">
                  <Collapse
                    defaultActiveKey={
                      props.values.stepFourTogg1
                        ? props.values.stepFourTogg1
                        : ["1", "2", "3", "4"]
                    }
                    onChange={props.handleChange("stepFourTogg1")}
                    className="reportCollapse"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <MinusOutlined className="custom-icon" />
                      ) : (
                        <PlusOutlined className="custom-icon" />
                      )
                    }
                    ghost
                  >
                    <Panel header="Jobsite Conditions at Inspection" key="1">
                      <div className="inspecContent">
                        <Row gutter={16}>
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">

                              <Form.Item
                                label="Average Moisture Content at Inspection"
                                name="avr_mois_cont"
                              >
                                {console.warn('TripleToggle')}
                                <TripleToggle
                                  value={props.values.avr_mois_cont}
                                  onToggle={props.handleChange('avr_mois_cont')}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          {props.isOpen.avr_mois_contField === true ||
                            props.values.avr_mois_cont === 'Yes' ? (
                            <>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="Floor" name="avr_floor">
                                    <Input
                                      placeholder="Floor"
                                      className="formControl"
                                      onChange={props.handleChange("avr_floor")}
                                      defaultValue={props.values.avr_floor}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="Top" name="avr_top">
                                    <Input
                                      placeholder="Top"
                                      className="formControl"
                                      onChange={props.handleChange("avr_top")}
                                      defaultValue={props.values.avr_top}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="Back" name="avr_back">
                                    <Input
                                      placeholder="Back"
                                      className="formControl"
                                      onChange={props.handleChange("avr_back")}
                                      defaultValue={props.values.avr_back}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Moisture Meter Type"
                                    name="avr_moist_meter"
                                  >
                                    <Input
                                      placeholder="Moisture Meter Type"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "avr_moist_meter"
                                      )}
                                      defaultValue={
                                        props.values.avr_moist_meter
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="Name" name="avr_name">
                                    <Input
                                      placeholder="Name"
                                      className="formControl"
                                      onChange={props.handleChange("avr_name")}
                                      defaultValue={props.values.avr_name}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="Model" name="avr_model">
                                    <Input
                                      placeholder="Model"
                                      className="formControl"
                                      onChange={props.handleChange("avr_model")}
                                      defaultValue={props.values.avr_model}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Meter Species Correction"
                                    name="avr_meter"
                                  >
                                    <Input
                                      placeholder="Meter Species Correction"
                                      className="formControl"
                                      onChange={props.handleChange("avr_meter")}
                                      defaultValue={props.values.avr_meter}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Number of Readings"
                                    name="avr_num_reading"
                                  >
                                    <Input
                                      placeholder="Number of Readings"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "avr_num_reading"
                                      )}
                                      defaultValue={
                                        props.values.avr_num_reading
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="High Reading"
                                    name="avr_hight_read"
                                  >
                                    <Input
                                      placeholder="High Reading"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "avr_hight_read"
                                      )}
                                      defaultValue={props.values.avr_hight_read}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Low Reading"
                                    name="avr_low_read"
                                  >
                                    <Input
                                      placeholder="Low Reading"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "avr_low_read"
                                      )}
                                      defaultValue={props.values.avr_low_read}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Locations of High Readings"
                                    name="avr_loca_high"
                                  >
                                    <Input
                                      placeholder="Locations of High Readings"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "avr_loca_high"
                                      )}
                                      defaultValue={props.values.avr_loca_high}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          <Col xs={24} md={6} xl={6}>
                            <div className="formGroup">
                              <Form.Item
                                label="HVAC Testing"
                                name="hvac_tsti"
                              >
                                {console.warn('TripleToggle')}
                                <TripleToggle
                                  value={props.values.hvac_tsti}
                                  onToggle={props.handleChange('hvac_tsti')}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          {props.isOpen.hvac_tstiField === true ||
                            props.values.hvac_tsti === 'Yes' ? (
                            <>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup custom-form-group">
                                  <Form.Item
                                    label="Temperature"
                                    name="Temp_hvac"
                                  >
                                    <Input
                                      className="formGroup customPadding"
                                      addonAfter={
                                        <Cascader
                                          placeholder="F/C"
                                          onChange={props.handleChange(
                                            "measure_type17"
                                          )}
                                          options={options}
                                          defaultValue={
                                            props.values.measure_type17
                                          }
                                          className="custom-cascadar"
                                        />
                                      }
                                      onChange={props.handleChange("Temp_hvac")}
                                      defaultValue={props.values.Temp_hvac}
                                      placeholder="Temperature"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Relative Humidity(%)"
                                    name="rel_humidity"
                                  >
                                    <Input
                                      placeholder="Relative Humidity"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "rel_humidity"
                                      )}
                                      defaultValue={props.values.rel_humidity}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item label="Meter Used" name="m_use">
                                    <Input
                                      placeholder="Meter Used"
                                      className="formControl"
                                      onChange={props.handleChange("m_use")}
                                      defaultValue={props.values.m_use}
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          <Col xs={24} md={24}>
                            <div className="m-2 formGroup"><label>Moisture Test Results (include an average and number of tests performed):</label></div>
                          </Col>
                          <Col xs={24} md={24} xl={24}>
                            <div className="formGroup">
                              <div className="text-editor-wrapper">
                                <SunEditor
                                  setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                  setOptions={SunEditorOpts}
                                  onImageUploadBefore={onImageUploadBefore}
                                  setContents={moisTres}
                                  onChange={moisTresChange}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={24} md={24}>
                            <div className="m-2 formGroup"><label>Comparison Readings (Baseboards, Trim, Door, Cabinets, etc)</label></div>
                          </Col>
                          <Col xs={24} md={24} xl={24}>
                            <div className="formGroup">
                              <div className="text-editor-wrapper">
                                <SunEditor
                                  setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                  setOptions={SunEditorOpts}
                                  onImageUploadBefore={onImageUploadBefore}
                                  setContents={comReadings}
                                  onChange={comReadingsChange}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={24} md={24}>
                            <div className="m-2 formGroup"><label>Other Moisture Tests Conducted</label></div>
                          </Col>
                          <Col xs={24} md={24} xl={24}>
                            <div className="formGroup">
                              <div className="text-editor-wrapper">
                                <SunEditor
                                  setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                  setOptions={SunEditorOpts}
                                  onImageUploadBefore={onImageUploadBefore}
                                  setContents={othMoiTestConducted}
                                  onChange={othMoiTestConductedChange}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="Floor Tests" key="2">
                      <Row gutter={16}>
                        <Col xs={24} md={12} xl={12}>
                          <div className="formGroup">
                            <Form.Item
                              label="Average Measurement of Individual Boards Face Width"
                              name="avg_meas_indi_boa"
                            >
                              <Input
                                placeholder="Average Measurement of Individual Boards Face Width"
                                className="formControl"
                                onChange={props.handleChange(
                                  "avg_meas_indi_boa"
                                )}
                                defaultValue={props.values.avg_meas_indi_boa}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Measurement Across 10 Boards"
                              name="meas_acr_board"
                            >
                              <Input
                                placeholder="Measurement Across 10 Boards"
                                className="formControl"
                                onChange={props.handleChange("meas_acr_board")}
                                defaultValue={props.values.meas_acr_board}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="20 Boards" name="twe_boards">
                              <Input
                                placeholder="20 Boards"
                                className="formControl"
                                onChange={props.handleChange("twe_boards")}
                                defaultValue={props.values.twe_boards}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Average" name="avverage">
                              <Input
                                placeholder="Average"
                                className="formControl"
                                onChange={props.handleChange("avverage")}
                                defaultValue={props.values.avverage}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Average Measurement of Gaps"
                              name="avr_m_gaps"
                            >
                              <Input
                                placeholder="Average Measurement of Gaps"
                                className="formControl"
                                onChange={props.handleChange("avr_m_gaps")}
                                defaultValue={props.values.avr_m_gaps}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Cupping Measurements"
                              name="cupp_meas"
                            >
                              <Input
                                placeholder="Cupping Measurements"
                                className="formControl"
                                onChange={props.handleChange("cupp_meas")}
                                defaultValue={props.values.cupp_meas}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Buckling Measurements"
                              name="bucking_meas"
                            >
                              <Input
                                placeholder="Buckling Measurements"
                                className="formControl"
                                onChange={props.handleChange("bucking_meas")}
                                defaultValue={props.values.bucking_meas}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                          <div className="formGroup">

                            <Form.Item
                              label=" Does pattern of issue convey a relationship with building mechanical systems
                                  or foundation or slab cracks and/or settlement?"
                              name="does_patr_issue"
                            >
                              {console.warn('TripleToggle')}
                              <TripleToggle
                                value={props.values.does_patr_issue}
                                onToggle={props.handleChange('does_patr_issue')}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel header="Substrate Testing" key="3">
                      <Row gutter={16}>
                        <Col xs={24} md={6}>
                          <div className="formGroup">

                            <Form.Item
                              label="Concrete Slab Testing"
                              name="Co_s_test"
                            >
                              {console.warn('TripleToggle')}
                              <TripleToggle
                                value={props.values.Co_s_test}
                                onToggle={props.handleChange('Co_s_test')}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.Co_s_testField === true ||
                          props.values.Co_s_test === 'Yes' ? (
                          <><Col xs={24} md={12}>
                            <div className="formGroup">
                              <Form.Item
                                label="Moisture Test(s) Required by Flooring Manufacturer"
                                name="Mois_req_flo_man"
                              >
                                <Select
                                  placeholder="Moisture Test(s) Required by Flooring Manufacturer"
                                  mode="multiple"
                                  allowClear
                                  style={{
                                    width: '100%',
                                  }}
                                  defaultValue={
                                    props.values.Mois_req_flo_man
                                      ? props.values.Mois_req_flo_man
                                      : ""
                                  }
                                  onChange={props.handleChange(
                                    "Mois_req_flo_man"
                                  )}
                                >
                                  <Option value="RH (ASTM 2170)">
                                    RH (ASTM 2170)
                                  </Option>
                                  <Option value="Meter (ASTM 2659)">
                                    Meter (ASTM 2659)
                                  </Option>
                                  <Option value="CaCl (ASTM 1869)">
                                    CaCl (ASTM 1869)
                                  </Option>
                                  <Option value="Other"> Other</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                            {props.isOpen.Mois_req_flo_open === true ||
                              props.values.Mois_req_flo_man === "Other" ? (
                              <Col xs={24} md={8} xl={8}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Other"
                                    name="Mois_req_flo_man_other"
                                  >
                                    <Input
                                      placeholder="Other"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "Mois_req_flo_man_other"
                                      )}
                                      defaultValue={
                                        props.values.Mois_req_flo_man_other
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            ) : null}
                            <Col xs={24} md={8} xl={8}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Results of Required Test(s)"
                                  name="res_req"
                                >
                                  <Input
                                    placeholder="Results of Required Test(s)"
                                    className="formControl"
                                    onChange={props.handleChange("res_req")}
                                    defaultValue={props.values.res_req}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={8} xl={8}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Number of Tests"
                                  name="num_of_tets"
                                >
                                  <Input
                                    placeholder="Number of Tests"
                                    className="formControl"
                                    onChange={props.handleChange("num_of_tets")}
                                    defaultValue={props.values.num_of_tets}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={24}>
                              <div className="m-2 formGroup"><label>Location of Tests</label></div>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <div className="text-editor-wrapper">
                                  <SunEditor
                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                    setOptions={SunEditorOpts}
                                    onImageUploadBefore={onImageUploadBefore}
                                    setContents={lOfTets}
                                    onChange={lOfTetsChange}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={24} md={8} xl={12}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Test(s) Required by Adhesive Manufacturer"
                                  name="Mois_req_adhesive_manu"
                                >
                                  <Select
                                    placeholder="Moisture Test(s) Required by Adhesive Manufacturer"
                                    mode="multiple"
                                    allowClear
                                    style={{
                                      width: '100%',
                                    }}
                                    defaultValue={
                                      props.values.Mois_req_adhesive_manu
                                        ? props.values.Mois_req_adhesive_manu
                                        : ""
                                    }
                                    onChange={props.handleChange(
                                      "Mois_req_adhesive_manu"
                                    )}
                                  >
                                    <Option value="RH (ASTM 2170)">
                                      RH (ASTM 2170)
                                    </Option>
                                    <Option value="Meter (ASTM 2659)">
                                      Meter (ASTM 2659)
                                    </Option>
                                    <Option value="CaCl (ASTM 1869)">
                                      CaCl (ASTM 1869)
                                    </Option>
                                    <Option value="Other"> Other</Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Col>
                            {props.isOpen.Mois_req_adhesive_manu_open ===
                              true ||
                              props.values.Mois_req_adhesive_manu === "Other" ? (
                              <Col xs={24} md={6} xl={6}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Other"
                                    name="Mois_req_adhesive_man_other"
                                  >
                                    <Input
                                      placeholder="Other"
                                      className="formControl"
                                      onChange={props.handleChange(
                                        "Mois_req_adhesive_man_other"
                                      )}
                                      defaultValue={
                                        props.values.Mois_req_adhesive_man_other
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                            ) : null}
                            <Col xs={24} md={8} xl={8}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Results of Required Test(s)"
                                  name="res_req_adhes_man"
                                >
                                  <Input
                                    placeholder="Results of Required Test(s)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "res_req_adhes_man"
                                    )}
                                    defaultValue={
                                      props.values.res_req_adhes_man
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={8} xl={8}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Number of Tests"
                                  name="num_of_tets_adh_manu"
                                >
                                  <Input
                                    placeholder="Number of Tests"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "num_of_tets_adh_manu"
                                    )}
                                    defaultValue={
                                      props.values.num_of_tets_adh_manu
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={24}>
                              <div className="m-2 formGroup"><label>Location of Tests</label></div>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <div className="text-editor-wrapper">
                                  <SunEditor
                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                    setOptions={SunEditorOpts}
                                    onImageUploadBefore={onImageUploadBefore}
                                    setContents={lofTetsAdheManu}
                                    onChange={lofTetsAdheManuChange}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Other Moisture Test(s) Conducted"
                                  name="other_moisture_test_cond"
                                >
                                  <Input
                                    placeholder="Other Moisture Test(s) Conducted"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "other_moisture_test_cond"
                                    )}
                                    defaultValue={
                                      props.values.other_moisture_test_cond
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Results of Required Test(s)"
                                  name="res_rt"
                                >
                                  <Input
                                    placeholder="Results of Required Test(s)"
                                    className="formControl"
                                    onChange={props.handleChange("res_rt")}
                                    defaultValue={props.values.res_rt}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={24}>
                              <div className="m-2 formGroup"><label>Location of Test(s)</label></div>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <div className="text-editor-wrapper">
                                  <SunEditor
                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                    setOptions={SunEditorOpts}
                                    onImageUploadBefore={onImageUploadBefore}
                                    setContents={locatiOfTests}
                                    onChange={locatiOfTestsChange}
                                  />
                                </div>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">

                            <Form.Item
                              label="Wood Subfloor Testing"
                              name="wood_sub_test"
                            >
                              {console.warn('TripleToggle')}
                              <TripleToggle
                                value={props.values.wood_sub_test}
                                onToggle={props.handleChange('wood_sub_test')}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.wood_sub_testField === true ||
                          props.values.wood_sub_test === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Content Readings"
                                  name="wood_mois_cont_read"
                                >
                                  <Input
                                    placeholder="Moisture Content Readings"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "wood_mois_cont_read"
                                    )}
                                    defaultValue={
                                      props.values.wood_mois_cont_read
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="High Reading"
                                  name="wood_sub_low_read_hight_read"
                                >
                                  <Input
                                    placeholder="High Reading"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "wood_sub_low_read_hight_read"
                                    )}
                                    defaultValue={
                                      props.values.wood_sub_low_read_hight_read
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Low Reading"
                                  name="wood_sub_low_read"
                                >
                                  <Input
                                    placeholder="Low Reading"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "wood_sub_low_read"
                                    )}
                                    defaultValue={
                                      props.values.wood_sub_low_read
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={12} >
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Test Results (include an average and number of tests performed):"
                                  name="wood_mois_t_res"
                                >
                                  <Input
                                    placeholder="Moisture Test Results (include an average and number of tests performed):"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "wood_mois_t_res"
                                    )}
                                    defaultValue={props.values.wood_mois_t_res}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">

                                <Form.Item
                                  label="Moisture on Top"
                                  name="wood_mois_on_top"
                                >
                                  {console.warn('TripleToggle')}
                                  <TripleToggle
                                    value={props.values.wood_mois_on_top}
                                    onToggle={props.handleChange('wood_mois_on_top')}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">

                                <Form.Item
                                  label="Moisture in Core"
                                  name="wood_mois_in_core"
                                >
                                  {console.warn('TripleToggle')}
                                  <TripleToggle
                                    value={props.values.wood_mois_in_core}
                                    onToggle={props.handleChange('wood_mois_in_core')}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">

                                <Form.Item
                                  label="Moisture on Underside"
                                  name="wood_mois_on_underside"
                                >
                                  {console.warn('TripleToggle')}
                                  <TripleToggle
                                    value={props.values.wood_mois_on_underside}
                                    onToggle={props.handleChange('wood_mois_on_underside')}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Meter Name" name="mois_name">
                                  <Input
                                    placeholder="Meter Name"
                                    className="formControl"
                                    onChange={props.handleChange("mois_name")}
                                    defaultValue={props.values.mois_name}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Meter Type"
                                  name="mois_mtrtype"
                                >
                                  <Input
                                    placeholder="Moisture Meter Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "mois_mtrtype"
                                    )}
                                    defaultValue={props.values.mois_mtrtype}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Meter Used"
                                  name="mois_mtr_usedsettings"
                                >
                                  <Input
                                    placeholder="Moisture Meter Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "mois_mtr_usedsettings"
                                    )}
                                    defaultValue={
                                      props.values.mois_mtr_usedsettings
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Meter Setting"
                                  name="mois_mtr_settings"
                                >
                                  <Input
                                    placeholder="Meter Setting"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "mois_mtr_settings"
                                    )}
                                    defaultValue={
                                      props.values.mois_mtr_settings
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Model" name="mois_mod">
                                  <Input
                                    placeholder="Model"
                                    className="formControl"
                                    onChange={props.handleChange("mois_mod")}
                                    defaultValue={props.values.mois_mod}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Joist Moisture Content"
                                  name="joismoiscont"
                                >
                                  <Input
                                    placeholder="Joist Moisture Content"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "joismoiscont"
                                    )}
                                    defaultValue={props.values.joismoiscont}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            {/* <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Comparison Readings (baseboards, trim, door, cabinets, etc.)"
                                  name="com_reading"
                                >
                                  <TextArea
                                    placeholder="Comparison Readings (baseboards, trim, door, cabinets, etc.)"
                                    className="formControl"
                                    onChange={props.handleChange("com_reading")}
                                    defaultValue={props.values.com_reading}
                                  />
                                </Form.Item>
                              </div>
                            </Col> */}
                            <Col xs={24} md={24}>
                              <div className="m-2 formGroup"><label>Comparison Readings (baseboards, trim, door, cabinets, etc.)</label></div>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <div className="text-editor-wrapper">
                                  <SunEditor
                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                    setOptions={SunEditorOpts}
                                    onImageUploadBefore={onImageUploadBefore}
                                    setContents={comReading}
                                    onChange={comReadingChange}
                                  />
                                </div>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Panel>
                    <Panel header="Below Flooring System Testing" key="4">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">

                            <Form.Item
                              label="Crawl Space Testing"
                              name="crawlspace_test"
                            >
                              {console.warn('TripleToggle')}
                              <TripleToggle
                                value={props.values.crawlspace_test}
                                onToggle={props.handleChange('crawlspace_test')}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.crawlspace_testField === true ||
                          props.values.crawlspace_test === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup custom-form-group">
                                <Form.Item
                                  label="Temperature"
                                  name="crawl_temp"
                                >
                                  <Input
                                    className="formGroup customPadding"
                                    addonAfter={
                                      <Cascader
                                        placeholder="F/C"
                                        onChange={props.handleChange(
                                          "measure_type13"
                                        )}
                                        options={options}
                                        defaultValue={
                                          props.values.measure_type13
                                        }
                                        className="custom-cascadar"
                                      />
                                    }
                                    onChange={props.handleChange("crawl_temp")}
                                    defaultValue={props.values.crawl_temp}
                                    placeholder="Temperature"
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Relative Humidity(%)"
                                  name="crawl_rel_humidity"
                                >
                                  <Input
                                    placeholder="Relative Humidity"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "crawl_rel_humidity"
                                    )}
                                    defaultValue={
                                      props.values.crawl_rel_humidity
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Meter Used"
                                  name="crawl_m_use"
                                >
                                  <Input
                                    placeholder="Meter Used"
                                    className="formControl"
                                    onChange={props.handleChange("crawl_m_use")}
                                    defaultValue={props.values.crawl_m_use}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={24} xl={12}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Distance from Ground to Underside of Joists(inches/cm)"
                                  name="dist_groun_un"
                                >
                                  <Input
                                    placeholder="Distance from Ground to Underside of Joists"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "dist_groun_un"
                                    )}
                                    defaultValue={props.values.dist_groun_un}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Joist Moisture Content"
                                  name="j_m_cont"
                                >
                                  <Input
                                    placeholder="Joist Moisture Content"
                                    className="formControl"
                                    onChange={props.handleChange("j_m_cont")}
                                    defaultValue={props.values.j_m_cont}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Content Underside Subfloor"
                                  name="m_under_sub"
                                >
                                  <Input
                                    placeholder="Moisture Content Underside Subfloor"
                                    className="formControl"
                                    onChange={props.handleChange("m_under_sub")}
                                    defaultValue={props.values.m_under_sub}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Meter Used"
                                  name="below_meter_used"
                                >
                                  <Input
                                    placeholder="Meter Used"
                                    className="formControl"
                                    onChange={props.handleChange("below_meter_used")}
                                    defaultValue={props.values.below_meter_used}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">

                            <Form.Item
                              label="Basement Testing"
                              name="baseTesting"
                            >
                              {console.warn('TripleToggle')}
                              <TripleToggle
                                value={props.values.baseTesting}
                                onToggle={props.handleChange('baseTesting')}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.baseTestingField === true ||
                          props.values.baseTesting === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup custom-form-group">
                                <Form.Item
                                  label="Temperature in Basement"
                                  name="basementTemper"
                                >
                                  <Input
                                    className="formGroup customPadding"
                                    addonAfter={
                                      <Cascader
                                        placeholder="F/C"
                                        onChange={props.handleChange(
                                          "measure_type2"
                                        )}
                                        options={options}
                                        defaultValue={
                                          props.values.measure_type2
                                        }
                                        className="custom-cascadar"
                                      />
                                    }
                                    onChange={props.handleChange(
                                      "basementTemper"
                                    )}
                                    defaultValue={props.values.basementTemper}
                                    placeholder="Temperature in Basement"
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Relative Humidity(%) in Basement"
                                  name="basemnt_rel_humidity"
                                >
                                  <Input
                                    placeholder="Relative Humidity in Basement"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "basemnt_rel_humidity"
                                    )}
                                    defaultValue={
                                      props.values.basemnt_rel_humidity
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Meter Used for Basement"
                                  name="basmnt_m_use"
                                >
                                  <Input
                                    placeholder="Meter Used for Basement"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "basmnt_m_use"
                                    )}
                                    defaultValue={props.values.basmnt_m_use}
                                  />
                                </Form.Item>
                              </div>
                            </Col>

                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Joist Moisture Content in Basement"
                                  name="basemnt_j_m_cont"
                                >
                                  <Input
                                    placeholder="Joist Moisture Content in Basement"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "basemnt_j_m_cont"
                                    )}
                                    defaultValue={props.values.basemnt_j_m_cont}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={12} xl={12}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Moisture Content Underside Subfloor in Basement"
                                  name="bsmntm_under_sub"
                                >
                                  <Input
                                    placeholder="Moisture Content Underside Subfloor in Basement"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "bsmntm_under_sub"
                                    )}
                                    defaultValue={props.values.bsmntm_under_sub}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Meter Used in Basement"
                                  name="bsmntmee_us"
                                >
                                  <Input
                                    placeholder="Meter Used in Basement"
                                    className="formControl"
                                    onChange={props.handleChange("bsmntmee_us")}
                                    defaultValue={props.values.bsmntmee_us}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Panel>
                  </Collapse>
                </div>
                <div className="CollapseSection">
                  <div className="customObservationPanel">
                    <Row gutter={16}>
                      <Col xs={24} md={16} xl={16}>
                        <Collapse
                          defaultActiveKey={
                            props.values.stepFourTogg2
                              ? props.values.stepFourTogg2
                              : ["1", "2", "3", "4"]
                          }
                          onChange={props.handleChange("stepFourTogg2")}
                          className="reportCollapse"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <MinusOutlined className="custom-icon" />
                            ) : (
                              <PlusOutlined className="custom-icon" />
                            )
                          }
                          ghost
                        >
                          <Panel
                            header="Additional Testing Information"
                            key="1"
                          >
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="m-2"><i><b>
                                  Note: Please list all of the test performed along with the results and tool used for the tests. Add reference to supporting photos.
                                </b> </i>
                                </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="text-editor-wrapper">
                                  <SunEditor
                                    onFocus={(event) => {
                                      getSuggestion(
                                        "applicable-field-tests",
                                        "Hardwood"
                                      );
                                    }}
                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                    setOptions={SunEditorOpts}
                                    onImageUploadBefore={onImageUploadBefore}
                                    setContents={editorState6}
                                    onChange={onEditorStateChange6}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel
                            header="Applied Industry Standards"
                            key="2"
                          >
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="m-2"><i><b>
                                  Note: Please list the source from which standards were applied . Then copy/paste specific areas of content that directly apply to the Statement of Concern.                                   </b> </i> </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        getSuggestion(
                                          "industry_standards",
                                          "Hardwood"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState5}
                                      onChange={onEditorStateChange5}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Conclusion/Cause" key="3">
                            <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="m-2"><i><b>
                                  Note: Determined conclusion based on the facts, testing, observations, and relevant manufacturer/industry guidelines as supported within this report.</b> </i> </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        getSuggestion(
                                          "conclusion",
                                          "Hardwood"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState7}
                                      onChange={onEditorStateChange7}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Footer" key={4}>
                            <Col xs={24} md={12} xl={24}>
                              <Row
                                gutter={16}
                                className="footer-singnature-section"
                              >
                                <div className="formGroup">
                                  <Form.Item
                                    label="Upload Signature"
                                    name="upload_sign_toggle"
                                  >
                                    <Switch
                                      defaultChecked={
                                        props.values.upload_sign_toggle ===
                                          true ||
                                          props.isOpen.signatureField === true
                                          ? true
                                          : false
                                      }
                                      onChange={props.handleChange(
                                        "upload_sign_toggle"
                                      )}
                                    />
                                  </Form.Item>
                                </div>
                                {props.isOpen.signatureField === true ||
                                  props.values.upload_sign_toggle === true ? (
                                  <>
                                    <div className="image-wrapper">
                                      <Form.Item
                                        name="upload"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                      >
                                        <Upload
                                          name="avatar"
                                          listType="picture-card"
                                          className="avatar-uploader"
                                          showUploadList={false}
                                          customRequest={uploadImage}
                                          beforeUpload={beforeUpload}
                                          onChange={handleChangeImage}
                                        >
                                          {imageUrl ? (
                                            <img
                                              src={imageUrl}
                                              alt="avatar"
                                              style={{
                                                width: "100%",
                                              }}
                                            />
                                          ) : (
                                            uploadButton
                                          )}
                                        </Upload>
                                      </Form.Item>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Col>
                            <Col xs={24} md={24} xl={24}>
                              <Row gutter={16}>
                                <div className="formGroup">
                                  <Form.Item
                                    label="Footer Text"
                                    name="footer_text_info"
                                    valuePropName="checked"
                                  >
                                    <Switch
                                      defaultChecked={
                                        props.values.footer_text_info ===
                                          true ||
                                          props.isOpen.footerField === true
                                          ? true
                                          : false
                                      }
                                      onChange={props.handleChange(
                                        "footer_text_info"
                                      )}
                                    />
                                  </Form.Item>
                                </div>
                                {props.isOpen.footerField === true ||
                                  props.values.footer_text_info === true ? (
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        getSuggestion(
                                          "footer-text",
                                          "Hardwood"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState8}
                                      onChange={onEditorStateChange8}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Col>
                          </Panel>
                        </Collapse>
                      </Col>
                      <Col xs={24} md={8} xl={8} className="sticySidebar">
                        <Card className="custom-sidecard">
                          <h5 className="formGroupTitle1">
                            Applied Industry Standards
                          </h5>
                          <div className="searchWrapper">
                            <Input
                              prefix={<SearchOutlined />}
                              placeholder="Search for names.."
                              className="formControl"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <p>
                              Your citations will start appearing here when you
                              will start writing under observation or industry
                              standards.
                            </p>
                            {loading ? (
                              <div className="spinDiv">
                                <SkeletonIndustryStandard />
                              </div>
                            ) : (
                              <div className="innerCardWrap">
                                {filterStandards && search
                                  ? filterStandards?.map((item, index) => {
                                    return (
                                      <Card
                                        className="industryCard mb-2 sidebarCard"
                                        key={index}
                                      >
                                        <div className="industryCardBody">
                                          <div className="cardTopSection">
                                            <div className="headerSec">
                                              <h3
                                                className="industryTitle"
                                                dangerouslySetInnerHTML={{
                                                  __html: item.title,
                                                }}
                                              ></h3>
                                              <p
                                                className="industryDesc"
                                                dangerouslySetInnerHTML={{
                                                  __html: item.excerpt,
                                                }}
                                              ></p>
                                            </div>
                                          </div>
                                          <div className="contentSec">
                                            <div className="btnGroup">
                                              <Tooltip title="Copy/Paste">
                                                <Button
                                                  type="text"
                                                  id={item.id}
                                                  className="indstryBtn viewBtn"
                                                  onClick={() =>
                                                    sendTextToEditor(
                                                      item.description,
                                                      item.label
                                                    )
                                                  }
                                                >
                                                  <CopyOutlined />
                                                </Button>
                                              </Tooltip>
                                              <Tooltip title="Edit">
                                                <Button
                                                  type="text"
                                                  id={item.id}
                                                  className="indstryBtn"
                                                >
                                                  <FormOutlined />
                                                </Button>
                                              </Tooltip>
                                              <Tooltip title="View More">
                                                <Button
                                                  type="text"
                                                  id={item.id}
                                                  className="indstryBtn"
                                                  onClick={() =>
                                                    handleClick(item.id)
                                                  }
                                                >
                                                  <EyeOutlined />
                                                </Button>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    );
                                  })
                                  : sidebarState?.map((item, index) => {
                                    return (
                                      <Card
                                        className="industryCard mb-2 sidebarCard"
                                        key={index}
                                      >
                                        <div className="industryCardBody">
                                          <div className="cardTopSection">
                                            <div className="headerSec">
                                              <h3
                                                className="industryTitle"
                                                dangerouslySetInnerHTML={{
                                                  __html: item.title,
                                                }}
                                              ></h3>
                                              <p
                                                className="industryDesc"
                                                dangerouslySetInnerHTML={{
                                                  __html: item.excerpt,
                                                }}
                                              ></p>
                                            </div>
                                          </div>
                                          <div className="contentSec">
                                            <div className="btnGroup">
                                              <Tooltip title="Copy/Paste">
                                                <Button
                                                  type="text"
                                                  id={item.id}
                                                  className="indstryBtn viewBtn"
                                                  onClick={() =>
                                                    sendTextToEditor(
                                                      item.description,
                                                      item.label
                                                    )
                                                  }
                                                >
                                                  <CopyOutlined />
                                                </Button>
                                              </Tooltip>
                                              {/* <Tooltip title="Edit">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                  >
                                                    <FormOutlined />
                                                  </Button>
                                                </Tooltip> */}
                                              <Tooltip title="View More">
                                                <Button
                                                  type="text"
                                                  id={item.id}
                                                  className="indstryBtn"
                                                  onClick={() =>
                                                    handleClick(item.id)
                                                  }
                                                >
                                                  <EyeOutlined />
                                                </Button>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </Card>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="attachmentSection">
                  <div className="attachmentHead">
                    <h4 className="formGroupTitle">Add Attachments</h4>
                  </div>
                  <Row className="custom-dropzone-row" gutter={16}>
                    <Upload1
                      takefileList={takefileList}
                      values={props.values}
                      setLoadings={props.setLoadings}

                    ></Upload1>
                  </Row>
                </div>
                <div className="attachmentSection">
                  <div className="CertificationHead">
                    <h4 className="formGroupTitle">Certification</h4>
                  </div>
                  <Row className="my-2 footerRow" gutter={6}>
                    {Object.keys(footerLogo).map((keyName, index) => (
                      <Form.Item name={[`footerLogo`, index]} key={index}>
                        <Col className="col-auto h-100" key={index}>
                          <div className="logoDiv">
                            <input
                              type="checkbox"
                              id={footerLogo[keyName].logo_id}
                              name="logoSelect[]"
                              defaultChecked={selectedlogs?.includes(
                                footerLogo[keyName].logo_id?.toString()
                              )}
                              onChange={props.handleChange("footerLogo")}
                              value={[footerLogo[keyName].logo_id]}
                            />

                            <label htmlFor={footerLogo[keyName].logo_id}>
                              <Image
                                src={footerLogo[keyName].logo_url}
                                className="img-fluid"
                                alt="logos"
                                preview={false}
                              />
                            </label>
                          </div>
                        </Col>
                      </Form.Item>
                    ))}
                  </Row>
                </div>
                <div className="attachmentSection">
                  <div className="CertificationHead">
                    <h4 className="formGroupTitle">Private Notes</h4>
                  </div>
                  <div className="p-3">
                    <div className="formGroup">
                      <Form.Item
                        label="Private Notes"
                        name="private_notes_togg"
                      >
                        <Switch
                          defaultChecked={
                            props.values.private_notes_togg === true ||
                              props.isOpen.notesField === true
                              ? true
                              : false
                          }
                          onChange={props.handleChange("private_notes_togg")}
                        />
                      </Form.Item>
                    </div>
                    {props.isOpen.notesField === true ||
                      props.values.private_notes_togg === true ? (
                      <div className="text-editor-wrapper">
                        <SunEditor
                          onFocus={(event) => {
                            getSuggestion("private_notes", "nwfa-installation");
                          }}
                          setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                          setOptions={SunEditorOpts}
                          onImageUploadBefore={onImageUploadBefore}
                          setContents={editorState9}
                          onChange={onEditorStateChange9}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Form>
      <Modal
        title={industryContent.title}
        centered
        open={modalIndustry}
        closable={false}
        className="inspectionCategoryModal industryModal"
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => setModalIndustry(false)}
            className="submitBtn"
          >
            Ok
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <p
              className="mcDescription"
              dangerouslySetInnerHTML={{ __html: industryContent.description }}
            ></p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FourthStep;
