import React , { useState }from 'react';
import { useSelector , useDispatch  } from 'react-redux'; 
import {
    Card,
    Row,
    Col,
    Typography,
    Divider,
    Image,
    Space,
    Button,
    Table
} from 'antd';
import {
    FileTextOutlined,
    ToolOutlined,
    SafetyCertificateOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import ImageGallery from '../../components/ImageGallery';
import { generateInspectionPdf } from '../../services/GetPdf';

// Utility function to generate consistent colors
const generateColor = (index) => {
    const colors = [
        '#0A497B', // Deep Blue
        '#1890FF', // Bright Blue
        '#52C41A', // Green
        '#FA8C16', // Orange
    ];
    return colors[index % colors.length];
};

// Reusable Component for Information Section
const InformationSection = ({
    title,
    fields,
    fullWidth = false,
    icon = null
}) => (
    <>
        <Divider orientation="left">
            <Space>
                {icon}
                <Typography.Text strong style={{ fontSize: '18px' }}>
                    {title}
                </Typography.Text>
            </Space>
        </Divider>
        <Row gutter={16}>
            {fields.map((field, index) => (
                <Col
                    xs={24}
                    md={fullWidth ? 24 : 6}
                    key={field.label}
                >
                    <Typography.Text
                        style={{
                            color: generateColor(index),
                            fontSize: "18px",
                        }}
                    >
                        {field.label}:
                    </Typography.Text>
                    <Typography.Paragraph>
                        {field.value || "-"}
                    </Typography.Paragraph>
                </Col>
            ))}
        </Row>
    </>
);

// Reusable Component for Downloadable Attachments
const AttachmentLink = ({
    href,
    label,
    icon,
    color
}) => (
    <Col xs={24} md={8} style={{ padding: '16px', marginBottom: '16px' }}>
        <Typography.Text style={{ color, fontSize: '18px', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            {React.cloneElement(icon, { style: { marginRight: 8, fontSize: '20px' } })}
            {label}:
        </Typography.Text>
        <Typography.Paragraph>
            <a
                href={href}
                target="_blank"
                rel="noreferrer"
                className="downloadLink"
                style={{
                    textDecoration: 'none',
                    color: color || '#1890ff',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '8px',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
            >
                <span>🔗</span>
                View {label}
            </a>
        </Typography.Paragraph>
    </Col>
);


const FlooringManufacturerRequest = ({
    requestData,
    attachments
}) => {


    const [loading, setLoading] = useState(false);  // For loading state
    const dispatch = useDispatch();  // Redux dispatch
  
    // Method to trigger PDF download
    const downloadPdf = () => {
      setLoading(true);  // Start loading
  
      const queryParams = new URLSearchParams(window.location.search);
      const req_id = queryParams.get("req_id");  // Get req_id from the URL
  
      if (!req_id) {
        console.error("Request ID is missing");
        setLoading(false);
        return;
      }
  
      // Dispatch the action to fetch the PDF
      dispatch(generateInspectionPdf(req_id))
        .then((res) => {
          console.info("PDF received:", res);
  
          // Open the PDF directly in a new window (res.data should contain the PDF URL or binary data)
          window.open(res);
  
          setLoading(false);  // End loading
        })
        .catch((err) => {
          console.error("Error fetching PDF:", err);
          setLoading(false);  // End loading
        });
    };




    
    const userDetails = useSelector((state) => state?.User?.userDetails);
    // Validate incoming data
    if (!requestData || requestData.request_type !== 'Flooring Manufacturer') {
        return <Typography.Text type="warning">Invalid Report Data</Typography.Text>;
    }
    const isExternalInspector = userDetails?.inspector_type === 'external';
    // Prepare product details columns
    const productDetailsColumns = [
        { title: "Style Name", dataIndex: "styleName", key: "styleName" },
        { title: "Color", dataIndex: "color", key: "color" },
        { title: "Back", dataIndex: "back", key: "back" },
        { title: "Lot", dataIndex: "lot", key: "lot" },
        { title: "Sysf", dataIndex: "sysf", key: "sysf" },
        ...(!isExternalInspector
            ? [{ title: "Unit Price", dataIndex: "unitPrice", key: "unitPrice" }]
            : [])
    ];

    // Prepare product details data source
    const productDetailsDataSource = (requestData.product_details || []).map((product, index) => {
        const baseDetails = {
            key: index,
            styleName: product["style-name"] || "-",
            color: product["product-color"] || "-",
            back: product["product-back"] || "-",
            lot: product["product-lot"] || "-",
            sysf: product["product-sysf"] || "-",
        };

        // Add Unit Price only for non-external inspectors
        if (!isExternalInspector) {
            baseDetails.unitPrice = product["product-unitprice"] || "-";
        }

        return baseDetails;
    });




    
    return (
        <Card
            className="reportCard"
            hoverable
            style={{
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                borderRadius: '8px'
            }}
        >
            {/* Header with Logo and Claim Details */}
            <div className="reportCardHeader">
                <Row gutter={30} align="middle">
                    {requestData?.manufacturer_logo && (
                        <Col xs={24} md={4}>
                            <Image
                                src={requestData.manufacturer_logo}
                                className="img-fluid manuFLogo"
                                alt="Manufacturer Logo"
                                preview={false}
                                style={{
                                    maxHeight: '100px',
                                    objectFit: 'contain'
                                }}
                            />
                        </Col>
                    )}
                    <Col xs={24} md={20}>
                        <Space direction="vertical" size="small">
                            <Typography.Title level={4} className="inspcTitle">
                                Claim No.: {requestData?.claim_number || 'N/A'}
                            </Typography.Title>
                            {requestData?.purchase_order && (
                                <Typography.Text>
                                    POA: {requestData.purchase_order}
                                </Typography.Text>
                            )}
                        </Space>
                    </Col>
                </Row>
            </div>

            {/* Manufacturer Details */}
            <InformationSection
                title="Manufacturer"
                fields={[
                    { label: "Manufacturer Name", value: requestData?.manufacturer_name },
                    { label: "Contact", value: requestData?.manufacturer_contact },
                    { label: "Phone Number", value: requestData?.manufacturer_phone_number },
                    { label: "Email Address", value: requestData?.manufacturer_email },
                ]}
            />

            {/* Manufacturer's Address */}
            <InformationSection
                title="Manufacturer's Address to Ship Samples"
                fields={[
                    { label: "Address", value: requestData?.manufacturer_address }
                ]}
                fullWidth
            />

            {/* Dealer Details */}
            <InformationSection
                title="Dealer"
                fields={[
                    { label: "Dealer Name", value: requestData?.dealer_name },
                    { label: "Contact", value: requestData?.dealer_contact },
                    { label: "Phone Number", value: requestData?.dealer_phone },
                    { label: "Email Address", value: requestData?.dealer_email },
                ]}
            />

            {/* Inspection Site */}
            <InformationSection
                title="Inspection Site"
                fields={[
                    { label: "Homeowner Name", value: requestData?.eu_name },
                    { label: "Address", value: requestData?.eu_address },
                    { label: "Zip Code", value: requestData?.eu_zip || "-" },
                    { label: "Mobile Number", value: requestData?.eu_mob },
                    { label: "Second Phone Number", value: requestData?.eu_phone2 },
                    { label: "Email Address", value: requestData?.eu_email },
                ]}
            />

            {/* Product to Inspect */}
            {(requestData?.style_name || requestData?.color || requestData?.quanty) && (
                <InformationSection
                    title="Product to Inspect"
                    fields={[
                        { label: "Style Name/Number", value: requestData?.style_name },
                        { label: "Color", value: requestData?.color },
                        { label: "Quantity", value: requestData?.quanty },
                    ]}
                />
            )}
            {/* Product Details */}
            {requestData?.product_details?.length > 0 && (
                <>
                    <Divider orientation="left">
                        <Typography.Text strong style={{ fontSize: '18px' }}>
                            Product Details
                        </Typography.Text>
                    </Divider>
                    <Table
                        dataSource={productDetailsDataSource}
                        columns={productDetailsColumns}
                        pagination={false}
                    />
                </>
            )}


            {/* Invoice Details */}
            {/* Invoice Details - Conditionally Rendered */}
            {requestData?.invoice_details?.length > 0 && !isExternalInspector && (
                <>
                    <Divider orientation="left">
                        <Typography.Text strong style={{ fontSize: '18px' }}>
                            Invoice Details
                        </Typography.Text>
                    </Divider>
                    <Table
                        dataSource={requestData.invoice_details.map((invoice, index) => ({
                            key: index,
                            invoice: invoice["invoice"] || "-",
                            date: invoice["invoice-date"] || "-",
                            amount: invoice["invoice-amount"] || "-",
                            installDate: invoice["invoice-install-date"] || "-",
                        }))}
                        columns={[
                            { title: "Invoice Number", dataIndex: "invoice", key: "invoice" },
                            { title: "Date", dataIndex: "date", key: "date" },
                            { title: "Amount", dataIndex: "amount", key: "amount" },
                            { title: "Install Date", dataIndex: "installDate", key: "installDate" },
                        ]}
                        pagination={false}
                    />
                </>
            )}


            {/* Flooring Category */}
            <InformationSection
                title="Flooring Category"
                fields={[
                    { label: "Category", value: requestData?.category },
                ]}
            />

            {/* Concerns */}
            <InformationSection
                title="Concerns"
                fields={[
                    { label: "Concerns", value: requestData?.concerns },
                    { label: "Property Type", value: requestData?.concern_type },
                    { label: "Inspection Type", value: requestData?.inspection_type },
                ]}
                fullWidth
            />

            {/* Installation Details */}
            <InformationSection
                title="Installation Details"
                fields={[
                    { label: "Installed By", value: requestData?.installed_by },
                    { label: "Installation Date", value: requestData?.installation_date },
                    { label: "Date Problem Noticed", value: requestData?.problem_date },
                ]}
            />

            {/* Comments Section */}
            <Card
                className="innerCard mb-3"
                style={{
                    backgroundColor: '#F5F5F5',
                    borderRadius: '8px'
                }}
            >
                <Typography.Text
                    style={{
                        fontSize: "16px",
                        color: "#0A497B",
                    }}
                >
                    Comments:
                </Typography.Text>
                <Typography.Paragraph>
                    {requestData?.comments || "-"}
                </Typography.Paragraph>
            </Card>
     
            {/* Uploaded Photos */}
            {attachments && Object.keys(attachments).length > 0 && (
                <>
                    <Divider orientation="left">
                        <Typography.Text strong style={{ fontSize: '18px' }}>
                            Uploaded Photos
                        </Typography.Text>
                    </Divider>
                    <div>
                        <ImageGallery attachments={attachments} />
                    </div>
                </>
            )}

            {/* Additional Attachments and Instructions */}
            {(requestData?.additional_attachments?.length > 0 ||
                requestData?.installation_instructions ||
                requestData?.maintenence_instructions ||
                requestData?.warranty_information ||
                requestData?.hard_surface_checklist) && (
                    <>
                        <Divider orientation="left">
                            <Typography.Text strong style={{ fontSize: '18px' }}>
                                Additional Attachments and Instructions
                            </Typography.Text>
                        </Divider>
                        <Row gutter={16}>
                            {requestData?.additional_attachments?.length > 0 && requestData?.additional_attachments?.map((attach, index) => (
                                <AttachmentLink
                                    key={index}
                                    href={attach}
                                    label={`Additional Attachment ${index + 1}`}
                                    icon={<FileTextOutlined />}
                                    color="#007BFF"
                                />
                            ))}

                            {requestData?.installation_instructions && (
                                <AttachmentLink
                                    href={requestData.installation_instructions}
                                    label="Installation Instructions"
                                    icon={<FileTextOutlined />}
                                    color="#007BFF"
                                />
                            )}

                            {requestData?.maintenence_instructions && (
                                <AttachmentLink
                                    href={requestData.maintenence_instructions}
                                    label="Care and Maintenance Instructions"
                                    icon={<ToolOutlined />}
                                    color="#28A745"
                                />
                            )}

                            {requestData?.warranty_information && (
                                <AttachmentLink
                                    href={requestData.warranty_information}
                                    label="Warranty Information"
                                    icon={<SafetyCertificateOutlined />}
                                    color="#FFC107"
                                />
                            )}

                            {requestData?.hard_surface_checklist && (
                                <AttachmentLink
                                    href={requestData.hard_surface_checklist}
                                    label="Hard Surface Checklist"
                                    icon={<CheckCircleOutlined />}
                                    color="#DC3545"
                                />
                            )}
                        </Row>
                    </>
                )}
                <div className='d-flex justify-content-center mt-4 mb-4'>
                    <Button
                        className="btnPrimary"
                        onClick={downloadPdf}
                        disabled={loading} // Disable button during loading
                    >
                        {loading ? "Downloading..." : "Download PDF"}
                    </Button>
                    </div>
        </Card>
        
    );
    
};

export default FlooringManufacturerRequest;